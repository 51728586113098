import BasicLayout from "../../layouts/BasicLayout.vue";
let moduleName = "WorkLogManager";
const RouteModules = [
  {
    path: "/work/log",
    redirect: "/work/log/list",
    name: "WorkLog",
    component: BasicLayout,
    showChildren: false,
    groupTitle: "公司管理",
    iconSvg: "log",
    iconFont: "",
    onlyExtentdLayout: true,
    children: [
      {
        path: "/work/log/list",
        name: "WorkLogList",
        component: () => import("@/views/workLog/WorkLogList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "工作汇报",
          keepAlive: true,
          iconSvg: "log",
          iconFont: "",
          roles: ['WORK_LOG_ADMIN', 'WORK_LOG_GROUP_ADMIN', 'WORK_LOG_USER', 'WORK_LOG_OBSERVER']
        }
      }
    ]
  }
];

export default RouteModules;
