export default [
    {
        iconName: "excel",
        fileType: "xlsx",
        mime: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        suffix: '.xlsx'
    },
    {
        iconName: "excel",
        fileType: "xls",
        mime: "application/vnd.ms-excel application/x-excel",
        suffix: '.xls'
    },
    {
        iconName: "img",
        fileType: "img",
        mime: "image/jpeg",
        suffix: '.jpg'
    },
    {
        iconName: "img",
        fileType: "img",
        mime: "image/jpg",
        suffix: '.jpg'
    },
    {
        iconName: "img",
        fileType: "img",
        mime: "image/png",
        suffix: '.png'
    },
    {
        iconName: "pdf",
        fileType: "pdf",
        mime: "application/pdf",
        suffix: '.pdf'
    },
    {
        iconName: "text",
        fileType: "text",
        mime: "text/plain",
        suffix: '.txt'
    },
    {
        iconName: "unknownFile",
        fileType: "UNKNOWN",
        mime: "",
        suffix: ''
    },
    {
        iconName: "word",
        fileType: "doc",
        mime: "application/msword",
        suffix: '.doc'
    },
    {
        iconName: "word",
        fileType: "docx",
        mime: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        suffix: '.docx'
    },
    {
        iconName: "mp3",
        fileType: "mpeg",
        mime: "audio/mpeg",
        suffix: '.mp3'
    },
    {
        iconName: "mp4",
        fileType: "mp4",
        mime: "video/mp4",
        suffix: '.mp4'
    },
    {
        iconName: "rtf",
        fileType: "rtf",
        mime: "application/rtf",
        suffix: '.rtf'
    },
    {
        iconName: "ppt",
        fileType: "ppt",
        mime: "application/vnd.ms-powerpoint",
        suffix: '.ppt'
    },
    {
        iconName: "ppt",
        fileType: "pptx",
        mime: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        suffix: '.pptx'
    },
    {
        iconName: "pps",
        fileType: "pps",
        mime: "application/vnd.ms-powerpoint",
        suffix: '.pps'
    },
    {
        iconName: "ppsx",
        fileType: "ppsx",
        mime: "application/vnd.openxmlformats-officedocument.presentationml.slideshow",
        suffix: '.ppsx'
    },
    {
        iconName: "swf",
        fileType: "swf",
        mime: "application/x-shockwave-flash",
        suffix: '.swf'
    },
    {
        iconName: "dll",
        fileType: "dll",
        mime: "application/x-msdownload",
        suffix: '.dll'
    },
    // {
    //     iconName: "exe",
    //     fileType: "exe",
    //     mime: "application/octet-stream",
    //     suffix: '.exe'
    // },
    // {
    //     iconName: "cab",
    //     fileType: "cab",
    //     mime: "application/octet-stream",
    //     suffix: '.cab'
    // },
    // {
    //     iconName: "msi",
    //     fileType: "msi",
    //     mime: "application/octet-stream",
    //     suffix: '.msi'
    // },
    // {
    //     iconName: "chm",
    //     fileType: "chm",
    //     mime: "application/octet-stream",
    //     suffix: '.chm'
    // },
    // {
    //     iconName: "ocx",
    //     fileType: "ocx",
    //     mime: "application/octet-stream",
    //     suffix: '.ocx'
    // },
    // {
    //     iconName: "rar",
    //     fileType: "rar",
    //     mime: "application/octet-stream",
    //     suffix: '.rar'
    // },
    {
        iconName: "tar",
        fileType: "tar",
        mime: "application/x-tar",
        suffix: '.tar'
    },
    {
        iconName: "tgz",
        fileType: "tgz",
        mime: "application/x-compressed",
        suffix: '.tgz'
    },
    {
        iconName: "zip",
        fileType: "zip",
        mime: "application/x-zip-compressed",
        suffix: '.zip'
    },
    {
        iconName: "z",
        fileType: "z",
        mime: "application/x-compress",
        suffix: '.z'
    },
    {
        iconName: "wav",
        fileType: "wav",
        mime: "audio/wav",
        suffix: '.wav'
    },
    {
        iconName: "wma",
        fileType: "wma",
        mime: "audio/x-ms-wma",
        suffix: '.wma'
    },
    {
        iconName: "wmv",
        fileType: "wmv",
        mime: "video/x-ms-wmv",
        suffix: '.wmv'
    },
    {
        iconName: "rm",
        fileType: "rm",
        mime: "application/vnd.rn-realmedia",
        suffix: '.rm'
    },
    {
        iconName: "mid",
        fileType: "mid",
        mime: "audio/mid",
        suffix: '.mid '
    },
    {
        iconName: "bmp",
        fileType: "bmp",
        mime: "audio/bmp",
        suffix: '.bmp '
    },
    {
        iconName: "gif",
        fileType: "gif",
        mime: "audio/image/gif",
        suffix: '.gif '
    },
    {
        iconName: "tif",
        fileType: "tif",
        mime: "image/tiff",
        suffix: '.tif '
    },
    {
        iconName: "xml",
        fileType: "xml",
        mime: "text/xml",
        suffix: '.xml '
    },
    {
        iconName: "html",
        fileType: "html",
        mime: "text/html",
        suffix: '.html '
    },
    {
        iconName: "css",
        fileType: "css",
        mime: "text/css",
        suffix: '.css '
    },
    {
        iconName: "js",
        fileType: "js",
        mime: "text/javascript",
        suffix: '.js '
    },
    {
        iconName: "mht",
        fileType: "mht",
        mime: "message/rfc822",
        suffix: '.mht '
    },
]