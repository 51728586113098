<template>
  <div class="message-read-count-wrap">
    <span class="cont-numer text-nav" @click="showReadDetail">{{
      tableData.length
    }}</span>
    <el-dialog
      title="阅读人数"
      :visible.sync="dialogVisible"
      width="600px"
      append-to-body
      :close-on-click-modal="false"
    >
      <div class="table-count">共：{{ tableData.length }} 条</div>
      <el-table :data="tableData" style="width: 100%" height="500px">
        <el-table-column prop="readerName" label="姓名" width="180">
        </el-table-column>
        <el-table-column prop="address" label="查阅时间">
          <template slot-scope="scope">
            <span>{{ scope.row.readAt | date_time }}</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import { GetClientMessagesReadLogById } from "@/views/client/api/message";
import { GetClientFollowUpsMessagesReadLogById } from "@/views/client/clientFollowUpsRecord/api/message";
import { GetClientInvitationsMessagesReadLogById } from "@/views/client/clientInvitations/api/message";
import { GetContractsMessagesReadLogById } from "@/views/contracts/api/message";
export default {
  name: "messageReadCount",
  props: {
    messageId: {
      type: Number,
      default: null,
    },
    messageType: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialogVisible: false,
      tableData: [],
    };
  },
  watch: {
    messageId: {
      handler() {
        this.getTableData();
      },
      immediate: true,
    },
  },
  methods: {
    showReadDetail() {
      this.dialogVisible = true;
    },
    getTableData() {
      if (this.messageType == "" && this.messageId == null) {
        return false;
      }
      switch (this.messageType) {
        case "client":
          this.getClientMessage();
          break;
        case "followUps":
          this.getFollowUpsMessage();
          break;
        case "invitations":
          this.getInvitationsMessage();
          break;
        case "contracts":
          this.getContractsMessage();
          break;

        default:
          break;
      }
    },
    getClientMessage() {
      GetClientMessagesReadLogById(this.messageId).then((res) => {
        this.tableData = res.data;
      });
    },
    getFollowUpsMessage() {
      GetClientFollowUpsMessagesReadLogById(this.messageId).then((res) => {
        this.tableData = res.data;
      });
    },
    getInvitationsMessage() {
      GetClientInvitationsMessagesReadLogById(this.messageId).then((res) => {
        this.tableData = res.data;
      });
    },
    getContractsMessage() {
      GetContractsMessagesReadLogById(this.messageId).then((res) => {
        this.tableData = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.table-count {
  font-size: 14px;
  text-align: left;
  color: #989898;
  padding: 4px 10px;
}
</style>