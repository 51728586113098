import { apiv1 } from "@/request/apiv1";
import qs from "qs";

// 获取客户阶段的列表
export function GetClientFollowUpsRecordAll() {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/clients/follow_ups/all`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}



// 获取跟进记录的分页列表
export function GetClientFollowUpsRecordList(params = { page: 0, size: 20 }) {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/clients/follow_ups/page?${qs.stringify(params, {
            arrayFormat: "repeat"
        })}`)
            .then(res => {
                if (res.status === 200 || res.status === 206) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 新建客户跟进记录
export function PostClientFollowUpsRecord(params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.post(`/v1/clients/follow_ups`, params)
            .then(res => {
                if (res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 根据id获取跟进记录的具体信息
export function GetClientFollowUpsRecordById(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/clients/follow_ups/${id}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 设置/取消星标
export function ToggleStartClientFollowUpsRecordById(id = "", bool = null) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/v1/clients/follow_ups/${id}/star/${bool}`)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}