import BasicLayout from "../../layouts/BasicLayout.vue";
let moduleName = "CompanyManager";
const RouteModules = [
  {
    path: "/clients/invitations",
    redirect: "/clients/invitations/list",
    name: "ClientInvitations",
    component: BasicLayout,
    groupTitle: "邀约管理",
    iconSvg: "invitations",
    iconFont: "",
    showChildren: false,
    onlyExtentdLayout: true,
    children: [
      {
        path: "/clients/invitations/list",
        name: "ClientInvitationsList",
        component: () => import("@/views/client/clientInvitations/ClientInvitationsList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "邀约管理",
          keepAlive: true,
          iconSvg: "invitations",
          iconFont: "",
          roles: ['CLIENT_ADMIN', 'CLIENT_GROUP_ADMIN', 'CLIENT_USER', 'CLIENT_OBSERVER']
        }
      }
    ]
  }
];

export default RouteModules;
