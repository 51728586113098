<template>
  <div class="meaasge-component-wrap">
    <div class="message-container">
      <div class="message-filter-wrap">
        <el-button-group>
          <el-button
            :type="tableFilters.isRead === false ? 'primary' : ''"
            @click="onChangeReadStatus(false)"
            ><i class="el-icon-message el-icon--left"></i>未读</el-button
          >
          <el-button
            :type="tableFilters.isRead === true ? 'primary' : ''"
            @click="onChangeReadStatus(true)"
            ><i class="el-icon-chat-dot-square el-icon--left"></i
            >已读</el-button
          >
          <el-button
            :type="tableFilters.isRead === null ? 'primary' : ''"
            @click="onChangeReadStatus(null)"
            ><i class="el-icon-chat-line-square el-icon--left"></i
            >全部</el-button
          >
          <el-button @click="handleRefresh"
            ><i class="el-icon-refresh el-icon--left"></i>刷新数据</el-button
          >
        </el-button-group>
      </div>
      <div class="message-table-wrap">
        <el-table
          :data="tableData"
          height="100%"
          border
          style="width: 100%:height:100%"
          v-loading="tableLoading"
          element-loading-text="拼命加载中..."
        >
          <el-table-column
            prop="read"
            label="阅读状态"
            width="80"
            align="center"
          >
            <template slot-scope="scope">
              <el-tag type="success" v-if="scope.row.read">已读</el-tag>
              <el-tag type="danger" v-if="!scope.row.read">未读</el-tag>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="read" label="提醒" align="center" width="110">
            <template slot-scope="scope">
              <el-tag type="danger" v-if="scope.row.needRemind"
                >需要提醒</el-tag
              >
              <el-tag type="success" v-if="!scope.row.needRemind"
                >不需要提醒</el-tag
              >
            </template>
          </el-table-column> -->
          <el-table-column
            prop="content"
            label="内容"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span
                :class="[!scope.row.read ? 'message-not-read' : 'message-read']"
                :title="!scope.row.read ? '点击设置为已读' : '已读'"
                @click="handleSetRead(scope.row)"
                >{{ scope.row.content }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="clientInvitationId"
            label="客户邀约"
            width="80"
          >
            <template slot-scope="scope">
              <el-button type="text" @click="handleDetail(scope.row)">
                查看邀约
              </el-button>
            </template>
          </el-table-column>
          <el-table-column prop="clientInvitationId" label="客户" width="150">
            <template slot-scope="scope">
              <el-button type="text" @click="handleClientDetail(scope.row)">
                {{ scope.row.clientInvitationClientName }}-{{
                  scope.row.clientInvitationClientMainContactPhoneNo
                }}
              </el-button>
            </template>
          </el-table-column>
          <el-table-column prop="remindForUsers" label="提醒人" width="120">
            <template slot-scope="scope">
              {{
                scope.row.remindForUsers.length > 0
                  ? scope.row.remindForUsers.map((item) => item.name).join(",")
                  : "暂无"
              }}
            </template>
          </el-table-column>

          <el-table-column prop="createdByName" label="阅读人数" width="120">
            <template slot-scope="scope">
              <MessageReadCount
                :messageId="scope.row.id"
                messageType="invitations"
              />
            </template>
          </el-table-column>
          <el-table-column prop="createdByName" label="创建人" width="120">
          </el-table-column>
          <el-table-column prop="createdAt" label="创建时间" width="130">
            <template slot-scope="scope">
              <span>{{ scope.row.createdAt | date_time }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="control" label="操作" width="80">
            <template slot-scope="scope">
              <el-button
                v-if="!scope.row.read"
                type="text"
                @click="handleSetRead(scope.row)"
              >
                标记已读
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagenation-wrap">
          <el-pagination
            class="base-table-pagination"
            :disabled="tableLoading"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page + 1"
            :page-sizes="[10, 20, 30, 40, 50, 60]"
            :page-size="size"
            :layout="pageLayout"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog
      title="邀约详情"
      :visible.sync="detaiDialog"
      append-to-body
      :close-on-click-modal="false"
      width="600px"
      @close="currentId = null"
    >
      <Detail :id="currentId" />
    </el-dialog>
  </div>
</template>

<script>
import { GetTableOrderParams } from "@/utils/table";
import { ShowApiError } from "@/request/error";
import Detail from "./Detail";
import {
  GetClientInvitationsMessagesList,
  PutClientInvitationsMessagesRead,
} from "../api/message";
import MessageReadCount from "@/components/MessageReadCount";
export default {
  name: "invitationMessage",
  components: { Detail, MessageReadCount },
  data() {
    return {
      detaiDialog: false,
      showForm: false,
      tableFilters: {
        isRead: null,
      },
      currentId: null,
      tableLoading: false,
      page: 0,
      size: 20,
      total: 0,
      tableData: [],
      pageLayout: "total, sizes, prev, pager, next, jumper",
      orderProp: "createdAt",
      orderDirection: "descending",
      orderPropObj: {},
    };
  },
  created() {
    this.page = 0;
    this.getTableData();
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val - 1;
      this.getTableData();
    },
    handleSizeChange(val) {
      this.page = 0;
      this.size = val;
      this.getTableData();
    },
    filterParams() {
      return {
        ...this.tableFilters,
      };
    },
    getTableData() {
      this.tableLoading = true;
      GetClientInvitationsMessagesList({
        page: this.page,
        size: this.size,
        sort: GetTableOrderParams(
          this.orderProp,
          this.orderDirection,
          this.orderPropObj
        ),
        ...this.filterParams(),
      })
        .then((res) => {
          let { content, totalElements } = res.data;
          this.tableData = content;
          this.total = totalElements;
          // 加载状态结束
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
          ShowApiError("拉取消息错误", err);
        });
    },
    handleRefresh() {
      this.page = 0;
      this.getTableData();
    },
    onChangeReadStatus(value) {
      this.tableFilters.isRead = value;
      this.page = 0;
      this.getTableData();
    },
    handleSetRead(row) {
      if (row.read) {
        return false;
      }
      PutClientInvitationsMessagesRead(row.id)
        .then(() => {
          this.getTableData();
          this.$store.dispatch("app/getGlobalMessageRemindCount");
        })
        .catch((err) => {
          ShowApiError("操作失败", err);
        });
    },
    handleDetail(row) {
      this.currentId = row.clientInvitationId;
      this.detaiDialog = true;
      // this.$emit("showdateil", row.clientInvitationId);
    },
    handleClientDetail(row) {
      this.$emit("close");
      this.$router.push({
        name: "CientsDetail",
        params: {
          id: row.clientInvitationClientId,
        },
        query: {
          name: row.clientInvitationClientName,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.meaasge-component-wrap {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  height: 500px;
  .message-container {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    position: relative;
    padding-top: 50px;
    .message-filter-wrap {
      box-sizing: border-box;
      padding-bottom: 10px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      top: 0;
      left: 0;
      .message-filter-item {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        color: #333;
        &:not(:last-child) {
          border-right: 1px solid #ddd;
        }
      }
    }
    .message-table-wrap {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      padding-bottom: 50px;
      position: relative;
      overflow: hidden;
      .pagenation-wrap {
        box-sizing: border-box;
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
}
</style>