import { apiv1 } from "@/request/apiv1";
import qs from "qs";

export function GetClientInvitationsMessageRemindCount() {
  return new Promise((resolve, reject) => {
    apiv1.get(`/v1/clients/invitations/messages/count/need_remind`)
      .then(res => {
        if (res.status === 200) {
          resolve(res)
        }
      })
      .catch(err => {
        reject(err)
      });
  })
}

export function GetClientInvitationsMessagesAll() {
  return new Promise((resolve, reject) => {
    apiv1.get(`/v1/clients/invitations/messages/all`)
      .then(res => {
        if (res.status === 200) {
          resolve(res)
        }
      })
      .catch(err => {
        reject(err)
      });
  })
}


export function GetClientInvitationsMessagesList(params = { page: 0, size: 20 }) {
  return new Promise((resolve, reject) => {
    apiv1.get(`/v1/clients/invitations/messages/page?${qs.stringify(params, {
      arrayFormat: "repeat"
    })}`)
      .then(res => {
        if (res.status === 200 || res.status === 206) {
          resolve(res)
        }
      })
      .catch(err => {
        reject(err)
      });
  })
}



export function GetClientInvitationsMessagesById(id = "") {
  return new Promise((resolve, reject) => {
    apiv1.get(`/v1/clients/invitations/messages/${id}`)
      .then(res => {
        if (res.status === 200) {
          resolve(res)
        }
      })
      .catch(err => {
        reject(err)
      });
  })
}

export function PostClientInvitationsMessages(params = {}) {
  return new Promise((resolve, reject) => {
    apiv1.post(`/v1/clients/invitations/messages`, params)
      .then(res => {
        if (res.status === 201) {
          resolve(res)
        }
      })
      .catch(err => {
        reject(err)
      });
  })
}

export function PutClientInvitationsMessagesRead(id = "") {
  return new Promise((resolve, reject) => {
    apiv1.put(`/v1/clients/invitations/messages/${id}/read`)
      .then(res => {
        if (res.status === 200 || res.status === 201) {
          resolve(res)
        }
      })
      .catch(err => {
        reject(err)
      });
  })
}

export function GetClientInvitationsMessagesReadLogById(id = "") {
  return new Promise((resolve, reject) => {
    apiv1.get(`/v1/clients/invitations/messages/${id}/read_logs`)
      .then(res => {
        if (res.status === 200) {
          resolve(res)
        }
      })
      .catch(err => {
        reject(err)
      });
  })
}