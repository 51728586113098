
import { MessageBox, Message } from 'element-ui';
import Vue from 'vue';
import store from "@/store";
import BasicConfig from "@/config";
import {
  GetSystemVersion
} from "@/views/system/api";
export function setSystemVersion(version = "") {
  if (version !== null && version !== undefined) {
    if (typeof version === 'string' && version !== "") {
      localStorage.setItem(`${BasicConfig.BRAND_NAME_EN}_version`, JSON.stringify(version));
      store.commit('auth/setVersion', version)
    }
  }
}

export function getSystemVersion() {
  if (localStorage.getItem(`${BasicConfig.BRAND_NAME_EN}_version`)) {
    return JSON.parse(localStorage.getItem(`${BasicConfig.BRAND_NAME_EN}_version`));
  } else {
    return false
  }
}

export function isSystemVersion() {
  return new Promise((resolve, reject) => {
    GetSystemVersion().then(res => {
      if (getSystemVersion()) {
        if (getSystemVersion() !== res.data) {
          resolve({oldVersion:getSystemVersion(),newVersion:res.data})
        }
      } else {
        setSystemVersion(res.data);
      }
    }).catch(err => {
      reject(err)
    })
  })
}

export function checkCurrentVersion() {
  isSystemVersion().then((res) => {
    const h = new Vue().$createElement;
    let message = h("div", null, [
      h("p", { style: "color:#333;font-size:16px;margin-bottom:10px" }, `发现新版本 version ${res.newVersion}，是否现在更新？`),
      h("p", { style: "color:#989898;font-size:14px;margin-bottom:10px" }, `当前系统版本 version ${res.oldVersion}`),
      h(
        "p",
        { style: "font-size:13px;color: rgb(245, 108, 108)" },
        "(注意：更新成功后不会有任何提示，如果您正在填写表单，请注意保存表单数据！可选择【下次再说】）！ "
      ),
    ]);
    MessageBox.confirm(message, "版本更新提示", {
      confirmButtonText: "马上更新",
      cancelButtonText: "下次再说",
      closeOnClickModal: false,
      type: "info ",
    })
      .then(() => {
        setSystemVersion(res.newVersion);
        Message({
          type: "success",
          showClose: true,
          duration:5000,
          message: "新版本载入成功！按【ctrl + F5】即可加载使用新版本",
        });
        // window.location.reload();
      })
      .catch(() => {
        Message({
          type: "info",
          message: "将在下次页面刷新后提示",
        });
      });
  })

}