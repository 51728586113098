import Vue from "vue";
import VueRouter from "vue-router";
import BasicLayout from "../layouts/BasicLayout.vue";
Vue.use(VueRouter);

import menus from "./modules/menus";
import iconManager from "./modules/iconManager";
import company from "./modules/company";
// import users from "./modules/users";
// import department from "./modules/department";
// import kpi from "./modules/kpi";
import followUpsRecord from "./modules/followUpsRecord";
import client from "./modules/client";
import clientInvitations from "./modules/clientInvitations";
import system from "./modules/system";
import question from "./modules/question";
import payments from "./modules/payments";
import contracts from "./modules/contracts";
import workLog from "./modules/workLog";
import mechants from "./modules/mechants";

import statisticAnalysis from "./modules/statisticAnalysis";

export const contantsRoutes = [
  {
    path: '/redirect/:path(.*)',
    component: () => import('@/views/redirect/index'),
    meta: {
      module: "",
      isMenu: false,
      title: "重定向",
      affix: false,
      keepAlive: false,
      iconSvg: "",
      iconFont: ""
    }
  },
  {
    path: "/login",
    name: "MainLogin",
    component: () => import("@/views/login"),
    meta: {
      module: "",
      isMenu: false,
      title: "登录",
      keepAlive: true,
      iconSvg: "poster",
      iconFont: ""
    }
  },
  {
    path: "/",
    name: "LayoutHome",
    redirect: "/home",
    component: BasicLayout,
    showChildren: false,
    onlyExtentdLayout: true,
    children: [
      {
        path: "/home",
        name: "Home",
        component: () => import("@/views/dashboard/index.vue"),
        meta: {
          module: "",
          isMenu: true,
          title: "主页",
          affix: true,
          keepAlive: true,
          iconSvg: "home",
          iconFont: ""
        }
      }
    ]
  }
];

export const asyncRoutes = [
  ...mechants,
  ...client,
  ...company,
  // ...users,
  ...followUpsRecord,
  ...clientInvitations,
  ...contracts,
  ...payments,
  ...question,
  // ...department,
  ...workLog,
  // ...kpi,
  ...statisticAnalysis,
  ...menus,
  ...system,
  ...iconManager,
  {
    path: "/401",
    name: "401",
    component: () => import("@/views/error/401.vue"),
    meta: {
      module: "error",
      isMenu: false,
      title: "401",
      keepAlive: true,
      iconClass: "404"
    }
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/error/404.vue"),
    meta: {
      module: "error",
      isMenu: false,
      title: "404",
      keepAlive: true,
      iconClass: "404"
    }
  },
  {
    path: "*",
    redirect: "/404",
    meta: {
      module: "error",
      isMenu: false,
      title: "*",
      keepAlive: true,
      iconClass: "404"
    }
  }
];

const router = new VueRouter({
  routes: contantsRoutes,
  // scrollBehavior(to, from, savedPosition) {
  //   console.log('savedPosition', savedPosition)
  //   if (savedPosition) {
  //     return savedPosition
  //   } else {
  //     return { x: 0, y: 0 }
  //   }
  // }
});

// 重置路由
export function resetRouter() {
  const newRouter = new VueRouter({
    routes: contantsRoutes
  });
  router.matcher = newRouter.matcher; // reset router
}

export default router;
