import { apiv1 } from "@/request/apiv1";
import qs from "qs";
import axios from "axios";

// 获取所有的联系人数据
export function GetContactsAll() {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/clients/contacts/all`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}


// 获取联系人的分页列表
export function GetContactsList(params = { page: 0, size: 20 }) {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/clients/contacts/page?${qs.stringify(params, {
            arrayFormat: "repeat"
        })}`)
            .then(res => {
                if (res.status === 200 || res.status === 206) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 根据id获取具体的联系人数据
export function GetContactsById(id = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/clients/contacts/${id}`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 新建公司
export function PostContacts(params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.post(`/v1/clients/contacts`, params)
            .then(res => {
                if (res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 修改指定的联系人的数据
export function PutContactsById(id = "", params = {}) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/v1/clients/contacts/${id}`, params)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}


// 联系人电话号码查重
export function DuplicateContactsPhoneNo(phoneNo = "") {
    return new Promise((resolve, reject) => {
        apiv1.get(`/v1/clients/contacts/${phoneNo}/duplicate`)
            .then(res => {
                if (res.status === 200) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}

// 启用/禁用联系人
export function ToggleContactsDisable(id = "", bool=null) {
    return new Promise((resolve, reject) => {
        apiv1.put(`/v1/clients/contacts/${id}/disable/${bool}`)
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    resolve(res)
                }
            })
            .catch(err => {
                reject(err)
            });
    })
}



/**
 * 批量禁用/启用 
 * @param {Array} multipleSelectieIds 需要批量处理的数据
 * @param {Boolean} bool true为禁用, false为启用
 */
export function MultipleToggleDisableContacts(multipleSelectieIds = [], bool=null) {
    return new Promise((resolve, reject) => {
        let multipleRequests = []
        multipleSelectieIds.forEach(item => {
            multipleRequests.push(apiv1.put(`/v1/clients/contacts/${item}/disable/${bool}`))
        })
        axios.all(multipleRequests)
            .then(axios.spread(() => {
                resolve();
            })).catch(err => {
                reject(err)
            });
    })
}