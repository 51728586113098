<template>
  <el-form
    :model="detail"
    ref="form"
    label-width="150px"
    class="demo-ruleForm"
    v-loading="loading"
  >
    <el-form-item label="客户：" prop="clientId">
      <span>{{ detail.clientName }}</span>
    </el-form-item>
    <el-form-item label="阶段" prop="clientStageName">
      <span>{{ detail.clientStageName }}</span>
    </el-form-item>
    <el-form-item label="跟进状态" prop="followUpStatusName">
      <span>{{ detail.followUpStatusName }}</span>
    </el-form-item>
    <el-form-item label="跟进内容" prop="content">
      <span>{{ detail.content }}</span>
    </el-form-item>
  </el-form>
</template>

<script>
import { GetClientFollowUpsRecordById } from "../api";
import { ShowApiError } from "@/request/error";
import { isEmprty } from "@/utils/validate";
export default {
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      detail: {
        clientName: "",
        followUpUsers: [],
        visitDate: "",
        visitorName: "",
        remark: "",
      },
    };
  },
  watch: {
    id: {
      handler() {
        if (!isEmprty(this.id)) {
          this.getFormData(this.id);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    isEmprty,
    getFormData(id = "") {
      this.loading = true;
      GetClientFollowUpsRecordById(id)
        .then((res) => {
          this.detail = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          ShowApiError("获取详情失败", err);
        });
    },
  },
};
</script>

<style>
</style>