<template>
  <el-form
    :model="form"
    status-icon
    :rules="rules"
    ref="form"
    label-width="100px"
    :disabled="submitting"
    class="password-form"
  >
    <el-form-item label="手机号码" prop="phoneNo">
      <el-input v-model="form.phoneNo" clearable :disabled="isCountting"></el-input>
    </el-form-item>
    <el-form-item label="验证码" prop="code">
      <el-row :gutter="10">
        <el-col :span="8"
          ><el-input v-model="form.code" clearable></el-input
        ></el-col>
        <el-col :span="8"
          ><el-button @click="onGetcodeByPhoneNo" :disabled="isCountting">{{
            isCountting ? `${countDown}s` : "点击获取验证码"
          }}</el-button></el-col
        >
      </el-row>
    </el-form-item>
    <el-form-item style="text-align: right">
      <el-button @click="handleClose">关闭</el-button>
      <el-button
        type="primary"
        @click="submitForm('form')"
        :loading="submitting"
        >{{ submitting ? "提交中..." : "提交修改" }}</el-button
      >
    </el-form-item>
  </el-form>
</template>

<script>
import { PutCurentUserPhoneEdit } from "@/views/users/api";
import { ShowApiError } from "@/request/error";
import {
  PostCodeByPhoneNumber,
  PutPhoneNumberVerify,
} from "@/utils/auth";
import { throttle } from "@/utils/common";
export default {
  data() {
    return {
      submitting: false,
      isCountting: false,
      countDown: 60,
      form: {
        phoneNo: "",
        code: "",
      },
      rules: {
        phoneNo: [
          {
            required: true,
            validator: this.checkPhoneNumber,
            trigger: "blur",
          },
        ],
        code: [
          {
            required: true,
            validator: this.onVerifyCode,
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    checkPhoneNumber(rule, value, callback) {
      // console.log('rule', rule)
      if (!value) {
        return callback(new Error("手机号码不能为空"));
      }
      let reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (reg.test(value)) {
        callback();
      } else {
        callback(new Error("电话号码格式不正确"));
      }
    },
    onVerifyCode(rule, value, callback) {
      // console.log('rule', rule)
      if (!value || value === "") {
        return callback(new Error("请输入验证码"));
      }
      if (this.form.phoneNo === "") {
        return callback(new Error("请先输入手机号码"));
      }
      throttle(() => {
        PutPhoneNumberVerify(this.form.phoneNo, value)
          .then((res) => {
            if (res.data) {
              callback();
            } else {
              callback(new Error("验证码验证失败，请重试"));
            }
          })
          .catch((err) => {
            callback(new Error(err.message));
          });
      }, 800)();
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          PutCurentUserPhoneEdit(this.form)
            .then(() => {
              this.submitting = false;
              this.$emit("success");
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("请求异常", err);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    onGetcodeByPhoneNo() {
      if (this.form.phoneNo === "") {
        this.$message.warning("电话号码不能为空");
        return false;
      }
      PostCodeByPhoneNumber(this.form.phoneNo)
        .then(() => {
          this.$message.success("验证码已发送");
          this.handleCountDown();
        })
        .catch((err) => {
          ShowApiError("请求异常", err);
        });
    },
    handleCountDown() {
      let timer = null;
      this.isCountting = true;
      timer = setInterval(() => {
        if (this.countDown > 0) {
          this.countDown = this.countDown - 1;
        } else {
          clearInterval(timer);
          this.isCountting = false;
          this.countDown = 60;
        }
      }, 1000);
    },
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>

<style>
</style>