import BasicLayout from "../../layouts/BasicLayout.vue";
let moduleName = "FollowUpsRecord";
const RouteModules = [
  {
    path: "/clients/followUps/",
    redirect: "/clients/followups/list",
    name: "ClientPublic",
    component: BasicLayout,
    showChildren: false,
    groupTitle: "跟进记录",
    iconSvg: "followRecord",
    iconFont: "",
    onlyExtentdLayout: true,
    children: [
      {
        path: "/clients/followups/list",
        name: "FollowUpsRecordList",
        component: () => import("@/views/client/clientFollowUpsRecord/ClientFollowUpsRecordList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "跟进记录",
          keepAlive: true,
          iconSvg: "followRecord",
          iconFont: "",
          roles: ['CLIENT_ADMIN', 'CLIENT_GROUP_ADMIN', 'CLIENT_USER', 'CLIENT_OBSERVER']
        }
      },
    ]
  }
];

export default RouteModules;
