<template>
  <div class="meaasge-component-wrap">
    <div class="message-container">
      <div class="message-filter-wrap">
        <el-row :gutter="10">
          <el-col :xs="24" :sm="12" :md="8">
            <el-date-picker
              v-model="tableFilters.claimAt"
              type="daterange"
              style="width: 100%"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :default-time="['00:00:00', '23:59:59']"
              @change="handleRefresh"
            >
            </el-date-picker>
          </el-col>
          <el-col :xs="24" :sm="12" :md="8">
            <BasicSelect
              multiple
              v-model="tableFilters.claimById"
              :allOptions="_ownerOptions"
              placeholder="请选择认领人"
              @change="handleRefresh"
            />
          </el-col>
          <el-col :xs="24" :sm="12" :md="8">
            <el-input
              v-model="tableFilters.clientName"
              placeholder="输入并按回车搜索"
              clearable
              @keyup.enter.native="handleRefresh"
              @clear="handleRefresh"
            ></el-input>
          </el-col>
        </el-row>
      </div>
      <div class="message-table-wrap">
        <el-table
          :data="tableData"
          height="100%"
          border
          style="width: 100%:height:100%"
          v-loading="tableLoading"
          element-loading-text="拼命加载中..."
        >
          <el-table-column
            prop="clientName"
            label="客户名称"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <span
                style="padding-left: 5px"
                title="点击查看客户详情"
                class="text-nav"
                @click="handleDetail(scope.row)"
                >{{ scope.row.clientName }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            prop="claimByName"
            label="认领人"
            width="150"
          ></el-table-column>

          <el-table-column prop="createdAt" label="认领时间" width="200">
            <template slot-scope="scope">
              <span>{{ scope.row.claimAt | date_time }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="control" label="操作" width="80">
            <template slot-scope="scope"> </template>
          </el-table-column> -->
        </el-table>
        <div class="pagenation-wrap">
          <el-pagination
            class="base-table-pagination"
            :disabled="tableLoading"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page + 1"
            :page-sizes="[10, 20, 30, 40, 50, 60]"
            :page-size="size"
            :layout="pageLayout"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BasicSelect from "@/components/BasicSelect";
import { GetTableOrderParams } from "@/utils/table";
import { ShowApiError } from "@/request/error";
import { GetClientCliamMessageList } from "../api";
export default {
  name: "invitationMessage",
  components:{
    BasicSelect
  },
  data() {
    return {
      showForm: false,
      tableFilters: {
        claimAt: [],
        claimById: null,
        clientName:""
      },
      currentId: null,
      tableLoading: false,
      page: 0,
      size: 20,
      total: 0,
      tableData: [],
      pageLayout: "total, sizes, prev, pager, next, jumper",
      orderProp: "claimAt",
      orderDirection: "descending",
      orderPropObj: {},
    };
  },
  computed:{
    _ownerOptions(){
      return this.$store.state.basicOption.ownerOptions
    }
  },
  created() {
    this.page = 0;
    this.getTableData();
    this.$store.dispatch("basicOption/initOwnerOption")
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val - 1;
      this.getTableData();
    },
    handleSizeChange(val) {
      this.page = 0;
      this.size = val;
      this.getTableData();
    },
    filterParams() {
      return {
        ...this.tableFilters,
      };
    },
    getTableData() {
      this.tableLoading = true;
      GetClientCliamMessageList({
        page: this.page,
        size: this.size,
        sort: GetTableOrderParams(
          this.orderProp,
          this.orderDirection,
          this.orderPropObj
        ),
        ...this.filterParams(),
      })
        .then((res) => {
          let { content, totalElements } = res.data;
          this.tableData = content;
          this.total = totalElements;
          // 加载状态结束
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
          ShowApiError("获取认领记录错误", err);
        });
    },
    handleRefresh() {
      this.page = 0;
      this.getTableData();
    },
    onChangeReadStatus() {
      this.page = 0;
      this.getTableData();
    },
    handleDetail(row) {
      this.$router.push({
        name: "CientsDetail",
        params: {
          id: row.id,
        },
        query: {
          name: row.name,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.meaasge-component-wrap {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  height: 500px;
  .message-container {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    position: relative;
    padding-top: 50px;
    .message-filter-wrap {
      box-sizing: border-box;
      padding-bottom: 10px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      top: 0;
      left: 0;
      .message-filter-item {
        flex: 1;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 15px;
        color: #333;
        &:not(:last-child) {
          border-right: 1px solid #ddd;
        }
      }
    }
    .message-table-wrap {
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      padding-bottom: 50px;
      position: relative;
      font-size: 14px;
      color: #ccc;
      overflow: hidden;
      .pagenation-wrap {
        box-sizing: border-box;
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
  }
}
</style>