import BasicLayout from "../../layouts/BasicLayout.vue";
let moduleName = "SystemMenu";
const RouteModules = [
  {
    path: "/menu",
    redirect: "/menu/list",
    name: "SystemMenu",
    component: BasicLayout,
    showChildren: false,
    groupTitle: "菜单管理",
    iconSvg: "list",
    iconFont: "",
    onlyExtentdLayout: true,
    children: [
      {
        path: "/menu/list",
        name: "SystemMenuList",
        component: () => import("@/views/system/menus/MenuList.vue"),
        meta: {
          module: moduleName,
          isMenu: false,
          showChildren: false,
          title: "菜单管理",
          keepAlive: true,
          iconSvg: "list",
          iconFont: "",
          roles: []
        }
      }
    ]
  }
];

export default RouteModules;
