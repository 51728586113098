import countryData from "@/assets/areas/countries.json";
import statesData from "@/assets/areas/states.json";
import citiesData from "@/assets/areas/cities.json";
import regionsData from "@/assets/areas/regions.json";
import { GetFilePath } from "@/utils/auth";
import { Message } from "element-ui";
import File_MIME from "@/utils/mimeType";
import store from "@/store";
import { format } from "date-fns";
import { GetDepartmentsTreeList } from "@/views/departments/api";

export function setTitle(title = "诺菲特") {
  document.title = title;
}

/**
 * 创建唯一的字符串
 * @return {string} ojgdvbvaua40
 */
export function createUniqueString() {
  const timestamp = +new Date() + ''
  const randomNum = parseInt((1 + Math.random()) * 65536) + ''
  return (+(randomNum + timestamp)).toString(32)
}

/**
 * 图片上传
 * @param {file} file el-upload文件对象
 * @param {number} size 限制的文件大小(kb) 默认10M
 */
export function validImgUpload(file, size) {
  size = +size || 10240
  const isSizeOut = file.size / 1024 > size
  if (isSizeOut) {
    Message.error('上传图片大小不能超过' + toStorage(size * 1024))
  }
  return !isSizeOut
}

/**
 * Filter asynchronous routing tables by recursion
 * @param fn 需要防抖的函数内容
 * @param interval 间隔时间
 */
export function debounce(fn, interval = 300) {
  let timeout = null;
  return function () {
    if (timeout !== null) clearTimeout(timeout);
    timeout = setTimeout(fn, interval);
  }
}

/**
 * Filter asynchronous routing tables by recursion
 * @param fn 需要节流的函数内容
 * @param interval 间隔时间
 */
export function throttle(fn, interval = 300) {
  let timer = null;
  let startTime = Date.now();
  return function () {
    let curTime = Date.now();
    let remaining = interval - (curTime - startTime);
    let context = this;
    let args = arguments;
    clearTimeout(timer);
    if (remaining <= 0) {
      fn.apply(context, args);
      startTime = Date.now();
    } else {
      timer = setTimeout(fn, remaining);
    }
  }
}

/**
 * 数字存储大小格式化
 * @param {number} num 存储大小 单位：Byte
 * @param {number} digits 保留几位小数
 * @return {string} 2MB
 */
export function toStorage(num, digits) {
  digits = digits || 2;
  if (num < 1024) {
    return num + "B";
  }
  num = (num * 1000) / 1024;
  const si = [
    { value: 1e18, symbol: "E" },
    { value: 1e15, symbol: "P" },
    { value: 1e12, symbol: "T" },
    { value: 1e9, symbol: "G" },
    { value: 1e6, symbol: "M" },
    { value: 1e3, symbol: "K" }
  ];
  for (let i = 0; i < si.length; i++) {
    if (num >= si[i].value) {
      return (
        (num / si[i].value)
          .toFixed(digits)
          .replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") +
        si[i].symbol +
        "B"
      );
    }
  }
}

/**
 * 数组对象排序
 */
export function compare(prop) {
  return function (obj1, obj2) {
    var val1 = Number(obj1[prop]);
    var val2 = Number(obj2[prop]);
    if (val1 < val2) {
      return -1;
    } else if (val1 > val2) {
      return 1;
    } else {
      return 0;
    }
  };
}

/**
 * 数组对象排序,由大到小
 */
export function compareReverse(prop) {
  return function (obj1, obj2) {
    var val1 = Number(obj1[prop]);
    var val2 = Number(obj2[prop]);
    if (val1 > val2) {
      return -1;
    } else if (val1 > val2) {
      return 1;
    } else {
      return 0;
    }
  }
}

/**
 * Parse the time to string
 * @param {(Object|string|number)} time
 * @param {string} cFormat
 * @returns {string}
 */
export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  const format = cFormat || "{y}-{m}-{d} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    if (typeof time === "string" && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === "number" && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
    let value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    if (result.length > 0 && value < 10) {
      value = "0" + value;
    }
    return value || 0;
  });
  return time_str;
}

export function timeFix() {
  const time = new Date()
  const hour = time.getHours()
  return hour < 9 ? '早上好' : hour <= 11 ? '上午好' : hour <= 13 ? '中午好' : hour < 20 ? '下午好' : '晚上好'
}

// IE检测
export function isIE() {
  const bw = window.navigator.userAgent
  const compare = (s) => bw.indexOf(s) >= 0
  const ie11 = (() => 'ActiveXObject' in window)()
  return compare('MSIE') || ie11
}

/**
 * @param num 需要转换成千位分隔符显示的数值
 */
export function NumFormat(num) {
  var res = num.toString().replace(/\d+/, function (n) {
    // 先提取整数部分
    return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1) {
      return $1 + ",";
    });
  });
  return res;
}


/**
*element 传入元素对象,设置该元素为全屏模式
*/

export function activateFullscreen(element) {
  if (element.requestFullscreen) {
    element.requestFullscreen();        // W3C spec
  }
  else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();     // Firefox
  }
  else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen();  // Safari
  }
  else if (element.msRequestFullscreen) {
    element.msRequestFullscreen();      // IE/Edge
  }
};

/**
 * 退出全屏
 */
export function deactivateFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  }
};


/**
 * 获取排序中文名称
 * @param {String} name 
 */
export function getOrderNameCn(name = "") {
  switch (name) {
    case "descending":
      return "倒序";
    case "ascending":
      return "正序";
    default:
      return "";
  }
}

/**
 * 获取用于识别后端的简写参数
 * @param {String} type 
 */
export function getOrderType(type = "") {
  switch (type) {
    case "descending":
      return "DESC";
    case "ascending":
      return "ASC";
    default:
      return "";
  }
}

/**
 * 根据属性名获取当前属性的名称，用于表格获取指定字段的中文名
 * @param {Array} columns 字段数组
 * @param {String,Number} prop 字段的属性值，用于筛选
 * @param {String,Number} key 指定用字段数组中的哪个属性来对比
 */
export function getSortPropName(columns, prop, key = "prop") {
  let result = columns.find((item) => item[key] === prop);
  if (result) {
    return result.label
  } else {
    return ""
  }
}


/**
 * 重新整理区域选择数据
 * @param countries
 * @param states
 * @param cities
 * @param regions
 */
export function GetFormatAreaInfo(countries = countryData.RECORDS, states = statesData.RECORDS, cities = citiesData.RECORDS, regions = regionsData.RECORDS) {
  return new Promise((resolve, reject) => {
    let tempCountry = countries ? countries : []
    let tempStates = states ? states : []
    let tempCity = cities ? cities : []
    let tempRegions = regions ? regions : []
    // 有些国家是没有省份的，需要把当前的国家信息填充到省份列表里面去
    tempCountry.forEach((country) => {
      if (tempStates.find(ele => ele.country_id === country.id)) {
        // 如果能找到有下级省份，那么检查名称是否为空的，如果是，那么把上级的名称填充下去
        tempStates.map((state) => {
          if (country.id === state.country_id) {
            if (state.cname === '') {
              state.cname = `${country.cname}(${country.name}) `
            }
          }
        })
      } else {
        tempStates.push({
          id: `${tempStates.length + 2}`,
          extend_id: country.id,
          country_id: country.id,
          name: country.name,
          cname: country.cname
        })
      }
    })
    tempStates.forEach((states) => {
      // 先检查这个省份或者州下面有没有城市，如果没有，那么将当前的省份或者州的信息加入到城市的列表中
      if (tempCity.find(ele => ele.state_id === states.id)) {
        // 如果能找到有下级城市，那么检查名称是否为空的，如果是，那么把上级的名称填充下去
        tempCity.map((city) => {
          if (states.id === city.state_id) {
            if (city.cname === '') {
              city.cname = `${states.cname}(${states.name}) `
            }
          }
        })
      } else {
        tempCity.push({
          id: `${tempCity.length + 2}`,
          extend_id: states.id,
          state_id: states.id,
          name: states.name,
          cname: states.cname
        })
      }
    })

    tempCity.forEach((cities) => {
      // 先检查这个省份或者州下面有没有城市，如果没有，那么将当前的省份或者州的信息加入到城市的列表中
      if (tempRegions.find(ele => ele.city_id === cities.id)) {
        // 如果能找到有下级城市，那么检查名称是否为空的，如果是，那么把上级的名称填充下去
        tempRegions.map((city) => {
          if (cities.id === city.city_id) {
            if (city.cname === '') {
              city.cname = `${cities.cname}(${cities.name}) `
            }
          }
        })
      } else {
        tempRegions.push({
          id: `${tempRegions.length + 2}`,
          extend_id: cities.id,
          state_id: cities.id,
          name: cities.name,
          cname: cities.cname
        })
      }
    })
    if (tempCountry.length > 0 && tempStates.length > 0 && tempCity.length > 0) {
      resolve(
        {
          tempCountry,
          tempStates,
          tempCity,
          tempRegions
        }
      )
    } else {
      reject({
        tempCountry,
        tempStates,
        tempCity,
        tempRegions
      })
    }
  })

}

export function formatFileList(fileNames = [], basePath = "") {
  let result = [];
  if (fileNames.length > 0) {
    result = fileNames.map((item, index) => {
      let url = GetFilePath(basePath, item);
      return {
        id: item,
        name: item,
        seq: index,
        type: "",
        url: url,
      };
    });
  } else {
    result = [];
  }
  return result;
}

export function formatFileListId(fileList = []) {
  let fileIds = [];
  if (fileList.length > 0) {
    fileIds = fileList.map((item) => item.id);
  }
  return fileIds;
}

export function getFileSizeName(limit) {
  var size = ''
  if (limit < 0.1 * 1024) {
    size = limit.toFixed(2) + ' B'
  } else if (limit < 0.1 * 1024 * 1024) {
    size = (limit / 1024).toFixed(2) + ' KB'
  } else if (limit < 0.1 * 1024 * 1024 * 1024) { //
    size = (limit / (1024 * 1024)).toFixed(2) + ' MB'
  } else {
    size = (limit / (1024 * 1024 * 1024)).toFixed(2) + ' GB'
  }
  let sizeStr = size + ''
  let index = sizeStr.indexOf('.')
  let dou = sizeStr.substr(index + 1, 2)
  if (dou === '00') {
    return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2)
  }
  return size
}

export function getFileTypeName(mime = "") {
  if (mime == "") {
    return '未知类型'
  }
  let result = File_MIME.find((item) => item.mime === mime);
  if (result) {
    return result.fileType;
  } else {
    return "未知类型";
  }
}

export function setStorage(key = "", value = "") {
  let _key = ""
  if (key === "") {
    return false
  }
  // 根据登录用户名划分储存区域
  if (store.getters.userInfo.username) {
    _key = `${store.getters.userInfo.username}_${key}`
  } else {
    _key = key
  }
  localStorage.setItem(_key, JSON.stringify(value));
}
export function getStorage(key = "") {
  let _key = ""
  if (key === "") {
    return ""
  }
  // 根据登录用户名划分储存区域
  if (store.getters.userInfo.username) {
    _key = `${store.getters.userInfo.username}_${key}`
  } else {
    _key = key
  }
  if (!localStorage.getItem(_key)) {
    return "";
  }
  return JSON.parse(localStorage.getItem(_key));
}

//颜色对象
export function randomRgbaColorGroup() {
  let r = Math.floor(Math.random() * 255);
  let g = Math.floor(Math.random() * 255);
  let b = Math.floor(Math.random() * 255);
  let group = []
  for (let i = 0; i <= 10; i++) {
    group.push(`rgba(${r},${g},${b},${i / 10})`)
  }
  return group
}

export function onClearFilter(obj = {}) {
  let filterObj = JSON.parse(JSON.stringify(obj))
  for (const key in filterObj) {
    if (typeof filterObj[key] === "object") {
      if (filterObj[key] instanceof Array) {
        filterObj[key] = [];
      } else {
        filterObj[key] = null;
      }
    } else {
      filterObj[key] = "";
    }
  }
  return filterObj
}

// 下载二进制文件
export function downloadFile(fileData = "", contentType = "", fileName = "") {
  if (fileData == "" && contentType == "" && fileName == "") {
    return
  }
  const url = window.URL.createObjectURL(
    new Blob([fileData], {
      type: contentType,
    })
  );
  const link = document.createElement("a");
  link.style.display = "none";
  link.href = url;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function setFontClass(element = "#app") {
  let timer = null
  timer = setTimeout(() => {
    clearTimeout(timer)
    let _element = document.querySelector(element)
    if (_element) {

      if (!_element.getAttribute("text-family-regular")) {
        _element.classList.add("text-family-regular")
      }
    }
  }, 1000);

  // document.querySelector(element).classList.add("text-family-regular")
}

/**
 * 
 * @param {date} startDate 
 * @param {date} endDate 
 * @returns ["2021-08-15T16:00:00.000Z","2021-08-16T15:59:59.000Z]
 */
export function formateDateToISOString(startDate = null, endDate = null, isNotSame = false) {
  let result = [];
  if (startDate !== null) {
    result.push(format(new Date(startDate), "yyyy/MM/dd"));
  } else {
    if (isNotSame) {
      result.push('1970/01/02');
    }
  }
  if (endDate !== null) {
    result.push(format(new Date(endDate), "yyyy/MM/dd"));
  } else {
    if (isNotSame) {
      result.push(format(new Date(), "yyyy/MM/dd"));
    }
  }
  if (result.length <= 0) {
    return [];
  }

  if (result.length == 1) {
    let firstDate = result[0].split("/");
    firstDate[2] = (Number(firstDate[2]) - 1) >= 10 ? (Number(firstDate[2]) - 1) + '' : '0' + (Number(firstDate[2]) - 1);
    firstDate = firstDate.join("-") + "T16:00:00.000Z";
    let endDate = result[0].split("/");
    endDate = endDate.join("-");
    endDate = endDate + "T15:59:59.000Z";
    result = [firstDate, endDate];
    return result
  }


  if (result.length == 2) {
    let firstDate = result[0].split("/");
    firstDate[2] = (Number(firstDate[2]) - 1) >= 10 ? (Number(firstDate[2]) - 1) + '' : '0' + (Number(firstDate[2]) - 1);
    firstDate = firstDate.join("-") + "T16:00:00.000Z";
    let endDate = result[1].split("/");
    endDate = endDate.join("-");
    endDate = endDate + "T15:59:59.000Z";
    result = [firstDate, endDate];
    return result
  }
}


/**
 * 生成全局唯一标识符方式 2
 * @returns String // "748eea29-f842-4af9-a552-e1e1aa3ed979"
 */
export function guid2() {
  function S4() {
    return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  }
  return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
}


/**
 * 生成全局唯一标识符方式 uuid
 * @returns String // "ffb7cefd-02cb-4853-8238-c0292cf988d5"
 */
export function uuid() {
  var s = [];
  var hexDigits = "0123456789abcdef";
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = "-";

  var uuid = s.join("");
  return uuid;
}

export function sumDepartmentIds(data) {
  let ids = [];
  data.forEach((item) => {
    if (!ids.includes(item.id)) {
      ids.push(item.id);
    }
    if (item.subs && item.subs.length > 0) {
      ids = [...ids, ...sumDepartmentIds(item.subs)];
    }
  });
  return ids;
}

export function GetDepartmentIdsBySelf() {
  return new Promise((resolve, reject) => {
    let userInfo = store.getters.userInfo
    if (store.getters.hasUserInfo) {
      GetDepartmentsTreeList(userInfo.departmentId)
        .then((res) => {
          resolve([
            userInfo.departmentId,
            ...sumDepartmentIds(res.data),
          ])
        })
        .catch(() => {
          reject([userInfo.departmentId])
        })
    } else {
      reject([])
    }
  })
}

