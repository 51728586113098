<template>
  <el-form
    :model="detail"
    ref="form"
    label-width="150px"
    class="demo-ruleForm"
    v-loading="loading"
  >
    <el-form-item label="客户：" prop="clientId">
      <span>{{ detail.clientName }}</span>
    </el-form-item>
    <el-form-item label="接待人：" prop="followUpUserIds">
      <span
        v-for="item in detail.followUpUsers"
        :key="item.id"
        style="padding-right: 10px"
        >{{ item.name }}</span
      >
    </el-form-item>
    <el-form-item label="负责人：" prop="clientOwnerName">
      <span>{{ detail.clientOwnerName }}</span>
    </el-form-item>
    <el-form-item label="考察情况：" prop="statusName">
      <span>{{ detail.statusName }}</span>
    </el-form-item>
    <el-form-item label="客户考察日期：" prop="visitDate">
      <span>{{ detail.visitDate }}</span>
    </el-form-item>
    <el-form-item label="客户改期日期：" prop="changedVisitDate">
      <span>{{ detail.visitDate }}</span>
    </el-form-item>
    <el-form-item label="提醒日期：" prop="remindDate">
      <span>{{ detail.remindDate }}</span>
    </el-form-item>
    <el-form-item label="到访人：" prop="visitorName">
      <span>{{ detail.visitorName }}</span>
    </el-form-item>
    <el-form-item label="备注：" prop="remark">
      <span>{{ detail.remark }}</span>
    </el-form-item>
  </el-form>
</template>

<script>
import { GetClientInvitationsById } from "../api";
import { ShowApiError } from "@/request/error";
import { isEmprty } from "@/utils/validate";
export default {
  props: {
    id: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      detail: {
        clientName: "",
        followUpUsers: [],
        visitDate: "",
        visitorName: "",
        remark: "",
      },
    };
  },
  watch: {
    id: {
      handler() {
        if (!isEmprty(this.id)) {
          this.getFormData(this.id);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    isEmprty,
    getFormData(id = "") {
      this.loading = true;
      GetClientInvitationsById(id)
        .then((res) => {
          this.detail = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
          ShowApiError("获取表单", err);
        });
    },
  },
};
</script>

<style>
</style>