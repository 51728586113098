<template>
  <div class="inline-form-wrapper">
    <div class="inline-input">
      <el-input
        size="small"
        placeholder="请输入"
        clearable
        v-model="text"
        @keyup.enter.native="onComfirm"
      >
      </el-input>
    </div>
    <div class="inline-control" v-if="!hiddenConfirm && !hiddenCancel">
      <el-button
        v-if="!hiddenConfirm"
        size="mini"
        icon="el-icon-check"
        @click.stop="onComfirm"
      ></el-button>
      <el-button
        type="danger"
        size="mini"
        icon="el-icon-close"
        @click.stop="onCancel"
        v-if="!hiddenCancel"
      ></el-button>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "confirm",
  },
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    hiddenConfirm: {
      type: Boolean,
      default: false,
    },
    hiddenCancel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      text: "",
    };
  },
  watch: {
    value: {
      handler(val) {
        if (val !== "") {
          this.text = val;
        }
      },
      immediate: true,
    },
    text: {
      handler(val) {
        if (val !== "") {
          if (this.hiddenConfirm) {
            this.$emit("confirm", this.text);
          }
        }
      },
    },
  },
  methods: {
    onComfirm() {
      this.$emit("confirm", this.text);
    },
    onCancel() {
      this.$emit("cancel", this.text);
    },
  },
};
</script>

<style lang="scss" scoped>
.inline-form-wrapper {
  box-sizing: border-box;
  width: 100%;
  font-size: 14px;
  display: flex;
  .inline-input {
    flex: auto;
  }
  .inline-control {
    box-sizing: border-box;
    padding-left: 5px;
    flex: 80px 0 0;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
</style>