import Vue from "vue";
import { getOrderType } from "./common";
import { getStorage } from "@/utils/common";

export function GetTableOrderParams(defaultOrderProp = "", defaultOrderDirection = null, defaultOrderPropObj = {}) {
  let orderPropName = "";
  if (defaultOrderProp !== "" && defaultOrderDirection !== null) {
    orderPropName = Object.prototype.hasOwnProperty.call(
      defaultOrderPropObj,
      defaultOrderProp
    )
      ? defaultOrderPropObj[defaultOrderProp]
      : defaultOrderProp;
    return `${orderPropName},${getOrderType(defaultOrderDirection)}`;
  } else {
    return null;
  }
}

export function GetTableFormatParams(propObj = {}, tableFilters = {}) {
  let filterParams = {};
  for (const key in tableFilters) {
    if (propObj[key]) {
      filterParams[propObj[key]] = tableFilters[key];
    } else {
      filterParams[key] = tableFilters[key];
    }
  }
  return filterParams;
}

export function GetPageAfterRowChange(page = 0, size = 0, tableData = [], total = 0) {
  let currentPage = 0;
  if (tableData.length === 0 || tableData.length < size) {
    let currentTotalPage = 0
    if (total <= size) {
      currentTotalPage = 1
    } else {
      currentTotalPage = Math.floor(total / size)
    }
    currentPage = page < currentTotalPage ? page : currentTotalPage;
  }
  return currentPage;
}

/**
 * 
 * @param {Array} multipleSelectes 需要处理的数组
 * @param {String} conditionsKey  过滤条件
 * @param {String} valueKey 值得key
 * @param {String} nameKey 名称得key
 * @returns 
 */
export function FormatMultipleItemIds(params = {}) {
  let _param = {
    multipleSelectes: [],
    conditionsKey: "canDisable",
    valueKey: 'id',
    nameKey: "name",
    ...params
  }
  // 批量删除数据里，筛选出允许删除的数据和不可删除的数据
  let ids = [];
  let names = [];
  let unableControl = [];
  _param.multipleSelectes.forEach((item) => {
    if (_param.conditionsKey === "") {
      ids.push(item[_param.valueKey]);
      names.push(item[_param.nameKey]);
    } else {
      if (item[_param.conditionsKey]) {
        ids.push(item[_param.valueKey]);
        names.push(item[_param.nameKey]);
      } else {
        unableControl.push(item);
      }
    }

  });
  let nameString = "";
  nameString = names.toString();
  return {
    ids,
    nameString,
    unableControl,
  };
}

/**
 * 
 * @param {Number} total 
 * @param {Number} ableControlCount 
 * @param {Number} unableControlCount 
 * @param {String} nameString 
 * @param {Boolean} flag 
 * @param {Srring} ableText 
 * @param {Srring} unAbleText 
 * @returns 
 */
export function GetTableDisableMessage(params = {}) {
  let _param = {
    total: 0,
    ableControlCount: 0,
    unableControlCount: 0,
    nameString: "",
    flag: false,
    ableText: "启用",
    unAbleText: "禁用",
    ...params
  }
  let vue = new Vue();
  const h = vue.$createElement;
  let message = h(
    "div",
    { style: "word-break:break-all" },
    [
      h('p', { style: "word-break:break-all" }, `已选中 ${_param.total} 条数据`),
      h('p', { style: "word-break:break-all;color:#f56c6c" }, `其中 ${_param.unableControlCount} 条不可操作`),
      h('p', { style: "word-break:break-all;color:#67c23a" }, ` ${_param.ableControlCount} 条数据可操作,【${_param.nameString}】`),
      h('p', { style: "word-break:break-all" }, `是否确定要继续${_param.flag ? _param.unAbleText : _param.ableText}？`),
    ]
  );
  return message
}

/**
 * 
 * @param {Number} total 
 * @param {Number} ableControlCount 
 * @param {Number} unableControlCount 
 * @param {String} nameString 
 * @param {String} text 
 * @returns 
 */
export function GetTableDeleteMessage(params = {}) {
  let _param = {
    total: 0,
    ableControlCount: 0,
    unableControlCount: 0,
    nameString: "",
    text: "删除",
    ...params
  }
  let vue = new Vue();
  const h = vue.$createElement;
  let message = h(
    "div",
    { style: "word-break:break-all" },
    [
      h('p', { style: "word-break:break-all" }, `已选中 ${_param.total} 条数据`),
      h('p', { style: "word-break:break-all;color:#f56c6c" }, `其中 ${_param.unableControlCount} 条不可操作`),
      h('p', { style: "word-break:break-all;color:#67c23a" }, ` ${_param.ableControlCount} 条数据可操作,【${_param.nameString}】`),
      h('p', { style: "word-break:break-all" }, `是否确定要继续${_param.text}？`),
    ]
  );
  return message
}

/**
 * 用于读取本地设置过的字段
 * @param {Array} pageColumn 
 * @returns 
 */
export function ReadLocalColumn(currentRoute = {}, pageColumn = []) {
  // pageColumn 为当前的页面的字段数组数据
  if (pageColumn.length <= 0) {
    return []
  }
  let tempColumnData = JSON.parse(JSON.stringify(pageColumn));
  if (getStorage(`${currentRoute.name}_tableColumn`)) {
    let localColumn = getStorage(`${currentRoute.name}_tableColumn`)
    tempColumnData = tempColumnData.map(item => {
      let target = localColumn.find(j => j.prop === item.prop)
      if (target) {
        if (item.showColumn !== target.showColumn) {
          item.showColumn = !item.showColumn
        }
      }
      return item
    })
  }
  return tempColumnData
}