/* eslint-disable */
import { GetUsersAll } from "@/views/users/api";
import { GetClientsAll } from "@/views/client/api";
import { getToken } from "@/utils/auth";
import regions from "@/assets/areas/regions.json";
import cities from "@/assets/areas/cities.json";
import states from "@/assets/areas/states.json";
import countries from "@/assets/areas/countries.json";
import areaCascader from "@/assets/areas/areaCascader.json";
import { GetDepartmentsAll, GetDepartmentsTreeList } from "@/views/departments/api";
import { GetClientOwnersAll } from "@/views/client/clientOwners/api";
import { GetClientStagesAll } from "@/views/client/clientStages/api";
import { GetClientTypesAll } from "@/views/client/clientTypes/api";
let chinaCascaderOptions = areaCascader.filter(item => item.id === "44");
import { checkPermission } from "@/utils/auth";
import { compare } from "@/utils/common";
const state = {
    userOptions: [],
    regionOptions: regions["RECORDS"],
    cityOptions: cities["RECORDS"],
    provinceOptions: states["RECORDS"],
    countryOptions: countries["RECORDS"],
    areaCascaderOptions: areaCascader,
    chinaCascaderOptions: chinaCascaderOptions,
    clientOptions: [],
    stageOptions: [],
    departmnetOptions: [],
    departmnetTreeOptions: [],
    ownerOptions: [],
    clientTypeOptions: []
}

const mutations = {
    SET_USER_OPTION(state, payload = []) {
        if (payload instanceof Array) {
            state.userOptions = payload
        }
    },
    SET_OWNER_OPTION(state, payload = []) {
        if (payload instanceof Array) {
            state.ownerOptions = payload
        }
    },
    SET_CLIENT_TYPE_OPTION(state, payload = []) {
        if (payload instanceof Array) {
            state.clientTypeOptions = payload
        }
    },

    SET_CLIETN_OPTIONS(state, payload = []) {
        if (payload instanceof Array) {
            state.clientOptions = payload
        }
    },
    SET_STAGE_OPTIONS(state, payload = []) {
        if (payload instanceof Array) {
            state.stageOptions = payload
        }
    },

    SET_DEPARTMENT_OPTIONS(state, payload = []) {
        if (payload instanceof Array) {
            state.departmnetOptions = payload
        }
    },
    SET_DEPARTMENT_TREE_OPTIONS(state, payload = []) {
        if (payload instanceof Array) {
            state.departmnetTreeOptions = payload
        }
    }
}

const actions = {
    initUserOption({ commit }) {
        if (getToken()) {
            GetUsersAll().then(res => {
                commit('SET_USER_OPTION', res.data)
            })
        }
    },
    initOwnerOption({ commit }) {
        if (checkPermission(['CLIENT_ADMIN', 'CLIENT_GROUP_ADMIN', 'CLIENT_USER', 'CLIENT_OBSERVER'])) {
            GetClientOwnersAll().then(res => {
                commit('SET_OWNER_OPTION', res.data)
            })
        }
    },

    initStageOption({ commit }) {
        GetClientStagesAll().then(res => {
            commit('SET_STAGE_OPTIONS', res.data)
        })
    },
    initClientTypeOtions({ commit }) {
        GetClientTypesAll().then(res => {
            commit('SET_CLIENT_TYPE_OPTION', res.data)
        })
    },
    initClientOption({ commit }) {
        if (checkPermission(['CLIENT_ADMIN', 'CLIENT_GROUP_ADMIN', 'CLIENT_USER', 'CLIENT_OBSERVER'])) {
            GetClientsAll().then(res => {
                let data = res.data.map(item => {
                    return {
                        ...item,
                        name: `${item.name} - ${item.mainContact !== null ? item.mainContact.phoneNo : ''}`
                    }
                })
                commit('SET_CLIETN_OPTIONS', data)
            })
        }
    },
    initDepartmentOption({ commit }) {
        GetDepartmentsAll().then(res => {
            commit('SET_DEPARTMENT_OPTIONS', res.data)
        })
    },
    initDepartmentTreeOption({ commit }) {
        function onClearSubs(data = []) {
            data.map(k => {
                if (k.subs && k.subs.length <= 0) {
                    delete k.subs
                    return k
                } else {
                    return onClearSubs(k.subs)
                }
            })
            return data.sort(compare("seq"));
        }
        GetDepartmentsTreeList().then(res => {
            let result = onClearSubs(res.data)
            commit('SET_DEPARTMENT_TREE_OPTIONS', result)
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
