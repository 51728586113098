import BasicLayout from "../../layouts/BasicLayout.vue";
let moduleName = "CompanyManager";
const RouteModules = [
    {
        path: "/statistic/analysis",
        redirect: "/clients/list",
        name: "statisticAnalysis",
        component: BasicLayout,
        showChildren: true,
        groupTitle: "统计分析",
        iconSvg: "statisticAnalysis",
        iconFont: "",
        onlyExtentdLayout: false,
        children: [
            {
                path: "/kpi/analysis",
                name: "kpiAnalysis",
                component: () => import("@/views/statisticAnalysis/kpiAnalysis/index.vue"),
                meta: {
                    module: moduleName,
                    isMenu: true,
                    showChildren: false,
                    title: "目标完成情况",
                    keepAlive: true,
                    iconSvg: "conversion",
                    iconFont: "",
                    roles: ['CLIENT_ADMIN', 'CLIENT_GROUP_ADMIN', 'CLIENT_USER', 'CLIENT_OBSERVER']
                }
            },

            {
                path: "/funnel/analysis",
                name: "FunnelAalysis",
                component: () => import("@/views/statisticAnalysis/funnelAalysis/index.vue"),
                meta: {
                    module: moduleName,
                    isMenu: true,
                    showChildren: false,
                    title: "招商漏斗分析",
                    keepAlive: true,
                    iconSvg: "funnelAalysis",
                    iconFont: "",
                    roles: ['CLIENT_ADMIN', 'CLIENT_GROUP_ADMIN', 'CLIENT_USER', 'CLIENT_OBSERVER']
                }
            },
            {
                path: "/rankings",
                name: "Rankings",
                component: () => import("@/views/statisticAnalysis/rankings/index.vue"),
                meta: {
                    module: moduleName,
                    isMenu: true,
                    showChildren: false,
                    title: "招商排行榜",
                    keepAlive: true,
                    iconSvg: "rankings",
                    iconFont: "",
                    roles: ['CLIENT_ADMIN', 'CLIENT_GROUP_ADMIN', 'CLIENT_USER', 'CLIENT_OBSERVER']
                }
            },
            {
                path: "/stage/pivot",
                name: "StagePivot",
                component: () => import("@/views/statisticAnalysis/stagesPivot/StagePivot.vue"),
                meta: {
                    module: moduleName,
                    isMenu: true,
                    showChildren: false,
                    title: "每日数据报表",
                    keepAlive: true,
                    iconSvg: "dataAnalysis",
                    iconFont: "",
                    roles: ['CLIENT_ADMIN', 'CLIENT_GROUP_ADMIN', 'CLIENT_USER', 'CLIENT_OBSERVER']
                }
            },
            {
                path: "/stage/pivot/client/list/:filters?",
                name: "StagePivotClientList",
                component: () => import("@/views/statisticAnalysis/stagesPivot/StagePivotClientList.vue"),
                meta: {
                    module: moduleName,
                    isMenu: false,
                    showChildren: false,
                    title: "每日数据报表-客户数据",
                    keepAlive: true,
                    iconSvg: "dataAnalysis",
                    iconFont: "",
                    roles: ['CLIENT_ADMIN', 'CLIENT_GROUP_ADMIN', 'CLIENT_USER', 'CLIENT_OBSERVER']
                }
            },
            {
                path: "/client/area/statistics",
                name: "clientAreaStatistics",
                component: () => import("@/views/client/clientAreaStatistics/index.vue"),
                meta: {
                    module: moduleName,
                    isMenu: true,
                    showChildren: false,
                    title: "地区汇总表",
                    keepAlive: true,
                    iconSvg: "clientAreaStatistics",
                    iconFont: "",
                    roles: ['CLIENT_ADMIN', 'CLIENT_GROUP_ADMIN', 'CLIENT_USER', 'CLIENT_OBSERVER']
                }
            },
            {
                path: "/client/type/statistics",
                name: "clientTypesStatistics",
                component: () => import("@/views/statisticAnalysis/clientTypesStatistics/index.vue"),
                meta: {
                    module: moduleName,
                    isMenu: true,
                    showChildren: false,
                    title: "客户类型汇总表",
                    keepAlive: true,
                    iconSvg: "clientAreaStatistics",
                    iconFont: "",
                    roles: ['CLIENT_ADMIN', 'CLIENT_GROUP_ADMIN', 'CLIENT_USER', 'CLIENT_OBSERVER']
                }
            },
            {
                path: "/conversion/analysis",
                name: "ConversionAnalysis",
                component: () => import("@/views/statisticAnalysis/conversionStatistics/index.vue"),
                meta: {
                    module: moduleName,
                    isMenu: true,
                    showChildren: false,
                    title: "转化率分析",
                    keepAlive: true,
                    iconSvg: "conversion",
                    iconFont: "",
                    roles: ['CLIENT_ADMIN', 'CLIENT_GROUP_ADMIN', 'CLIENT_USER', 'CLIENT_OBSERVER']
                }
            },
            {
                path: "/client/invitations/statistics",
                name: "clientInvitationsStatistics",
                component: () => import("@/views/client/clientInvitationsStatistics/index.vue"),
                meta: {
                    module: moduleName,
                    isMenu: true,
                    showChildren: false,
                    title: "邀约签约率",
                    keepAlive: true,
                    iconSvg: "invitationsStatistics",
                    iconFont: "",
                    roles: ['CLIENT_ADMIN', 'CLIENT_GROUP_ADMIN', 'CLIENT_USER', 'CLIENT_OBSERVER']
                }
            },
            {
                path: "/client/sources/statistics",
                name: "clientSourcesStatistics",
                component: () => import("@/views/client/clientSourcesStatistics/index.vue"),
                meta: {
                    module: moduleName,
                    isMenu: true,
                    showChildren: false,
                    title: "客源分析",
                    keepAlive: true,
                    iconSvg: "sourceStatistis",
                    iconFont: "",
                    roles: ['CLIENT_ADMIN', 'CLIENT_GROUP_ADMIN', 'CLIENT_USER', 'CLIENT_OBSERVER']
                }
            },
        ]
    }
];

export default RouteModules;
