<template>
  <div class="layout__header">
    <div class="layout__header-content clearfix">
      <div class="refresh-current-page" @click="onRefresh" title="刷新当前页面">
        <span><svg-icon iconClass="Refresh"></svg-icon></span>
      </div>
      <div class="fullscreen-toggle" @click="toggleFullScreen">
        <span v-if="!isFullScreen"
          ><svg-icon iconClass="fullscreen"></svg-icon
        ></span>
        <span v-if="isFullScreen"
          ><svg-icon iconClass="exit-fullscreen"></svg-icon
        ></span>
      </div>
      <div class="avatar-wrapper">
        <el-popover placement="bottom" width="300" trigger="click">
          <user />
          <div slot="reference" class="avatar">
            <svg-icon iconClass="user" style="font-size: 18px"></svg-icon>
            <span class="user-name">{{ useInfo.name }}</span>
            <i class="el-icon-arrow-down" style="font-size: 18px"></i>
          </div>
        </el-popover>
      </div>
      <div class="company-wrapper" @click="showCompany">
        <div class="company">
          <i class="el-icon-s-tools" style="font-size: 18px"></i>
          <!-- <svg-icon iconClass="el-icon-s-tools"></svg-icon> -->
          <span class="company-name">全局设置</span>
        </div>
      </div>
      <div class="global-message" @click="handleShowAllMessage">
        <el-badge :value="remindMessageCount" :max="99" class="message-badge">
          <i class="el-icon-bell"></i>
        </el-badge>
      </div>
    </div>

    <TagsView class="layout__tagview" />
    <el-dialog
      title="全局设置"
      append-to-body
      :close-on-click-modal="false"
      :show-close="false"
      :visible="showCompanySelfDialog"
      width="800px"
    >
      <GlobalSetting @cancel="onCompanySelfCancel" />
    </el-dialog>
    <el-dialog
      title="全局通知"
      :visible.sync="canShowAllMessage"
      :close-on-click-modal="false"
      append-to-body
      width="80%"
    >
      <el-tabs v-model="activeMessageTabName">
        <el-tab-pane
          :label="`客户 (${clientRemindMessageCount})`"
          name="client"
        >
          <ClientsMessage @close="canShowAllMessage = false" />
        </el-tab-pane>
        <el-tab-pane
          :label="`跟进 (${followUpsRemindMessageCount})`"
          name="follow"
          ><ClientFollowUpMessage @close="canShowAllMessage = false"
        /></el-tab-pane>
        <el-tab-pane
          :label="`邀约 (${invitationsRemindMessageCount})`"
          name="invitation"
          ><ClientInvitationsMessage @close="canShowAllMessage = false"
        /></el-tab-pane>

        <el-tab-pane
          :label="`合同 (${contractsRemindMessageCount})`"
          name="contracts"
          ><ContractsMessage @close="canShowAllMessage = false"
        /></el-tab-pane>
        <el-tab-pane label="认领客户" name="claim"><ClaimMessage /></el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>

<script>
import user from "../user";
import TagsView from "../TagsView";
import { activateFullscreen, deactivateFullscreen } from "@/utils/common";
import { onRefreshCurrentPage } from "@/utils/auth";
import GlobalSetting from "@/components/GlobalSetting";
import { checkPermission } from "@/utils/auth";
import ClientFollowUpMessage from "@/views/client/clientFollowUpsRecord/components/Message";
import ClientInvitationsMessage from "@/views/client/clientInvitations/components/Message";
import ClientsMessage from "@/views/client/components/Message";
import ContractsMessage from "@/views/contracts/components/Message";
import ClaimMessage from "@/views/client/clientPublic/components/ClaimMessage";
export default {
  components: {
    user,
    TagsView,
    ClaimMessage,
    GlobalSetting,
    ClientFollowUpMessage,
    ClientInvitationsMessage,
    ClientsMessage,
    ContractsMessage,
  },
  data() {
    return {
      activeMessageTabName: "client",
      canShowAllMessage: false,
      showCompanySelfDialog: false,
      isFullScreen: false,
      fullScreenTarget: null,
    };
  },
  computed: {
    isCollapse() {
      return this.$store.state.app.isSideBarCollapse;
    },
    useInfo() {
      return this.$store.getters.userInfo;
    },
    remindMessageCount() {
      return this.$store.getters.remindMessageCount;
    },
    clientRemindMessageCount() {
      return this.$store.getters.clientRemindMessageCount;
    },
    followUpsRemindMessageCount() {
      return this.$store.getters.followUpsRemindMessageCount;
    },
    invitationsRemindMessageCount() {
      return this.$store.getters.invitationsRemindMessageCount;
    },
    contractsRemindMessageCount() {
      return this.$store.getters.contractsRemindMessageCount;
    },
  },
  mounted() {
    this.$store.dispatch("app/getGlobalMessageRemindCount");
    this.upDateMessageByLoop();
    let _this = this;
    this.fullScreenTarget = document.querySelector("#app");
    document.addEventListener("fullscreenchange", function () {
      if (document.fullscreenElement) {
        _this.isFullScreen = true;
      } else {
        _this.isFullScreen = false;
      }
    });
  },
  methods: {
    checkPermission,
    onRefreshCurrentPage,
    upDateMessageByLoop() {
      let timer = null;
      timer = setInterval(() => {
        clearInterval(timer);
        this.$store.dispatch("app/getGlobalMessageRemindCount");
        this.upDateMessageByLoop();
      }, 1000 * 60);
    },
    handleShowAllMessage() {
      this.canShowAllMessage = true;
    },
    onFullScreen() {
      activateFullscreen(this.fullScreenTarget);
      this.isFullScreen = true;
    },
    onCloseFullScreen() {
      deactivateFullscreen(this.fullScreenTarget);
      this.isFullScreen = false;
    },
    toggleFullScreen() {
      if (this.isFullScreen) {
        this.onCloseFullScreen();
      } else {
        this.onFullScreen();
      }
    },
    onRefresh() {
      this.$nextTick(() => {
        onRefreshCurrentPage(this.$route.fullPath);
      });
    },
    showCompany() {
      this.showCompanySelfDialog = true;
    },
    onCompanySelfCancel() {
      this.showCompanySelfDialog = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/custom-variables.scss";
$tag-view-height: 30px;
.layout__header {
  width: 100%;
  min-height: 40px;
  padding-bottom: $tag-view-height;
  position: relative;
  .layout__header-content {
    width: 100%;
    height: 100%;
    text-align: right;
    position: relative;
    .refresh-current-page {
      float: left;
      width: 40px;
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 20px;
      justify-content: center;
      cursor: pointer;
      transition: all 0.2s linear;
      &:hover {
        background-color: #f1f1f1;
        color: #009d68;
        font-weight: bolder;
      }
    }
    .global-message {
      float: right;
      padding: 0 8px;
      width: 30px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-left: 1px solid #f1f1f1;
      border-right: 1px solid #f1f1f1;
      cursor: pointer;
      &:hover {
        background-color: #f1f1f1;
      }
      .message-badge {
        font-size: 16px;
      }
    }
    .company-wrapper {
      float: right;
      padding: 0 8px;
      min-width: 40px;
      max-width: 160px;
      height: 100%;
      border-right: 1px solid #f1f1f1;
      &:hover {
        background-color: #f1f1f1;
      }
      .company {
        width: 100%;
        height: 100%;
        font-size: 26px;
        color: #333;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        overflow: hidden;
      }
      .company-name {
        box-sizing: border-box;
        padding: 0 5px;
        display: inline-block;
        max-width: 100px;
        font-size: 14px;
        color: #333;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .avatar-wrapper {
      float: right;
      min-width: 40px;
      max-width: 160px;
      padding: 0 8px;
      height: 100%;
      &:hover {
        background-color: #f1f1f1;
      }
      .avatar {
        width: 100%;
        height: 100%;
        font-size: 26px;
        color: #333;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        overflow: hidden;
      }
      .user-name {
        box-sizing: border-box;
        padding: 0 5px;
        display: inline-block;
        max-width: 100px;
        font-size: 14px;
        color: #333;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .el-icon-arrow-down {
        color: $theme_color;
      }
    }
    .fullscreen-toggle {
      float: right;
      width: 40px;
      height: 100%;
      display: flex;
      align-items: center;
      font-size: 20px;
      justify-content: center;
      cursor: pointer;
      transition: all 0.2s linear;
      border-left: 1px solid #f1f1f1;
      &:hover {
        background-color: #f1f1f1;
        color: #0c4fd6;
        font-weight: bolder;
      }
    }
  }

  .layout__tagview {
    border-top: 1px solid #f1f1f1;
    position: absolute;
    bottom: 0;
    left: 0;
  }
  .user-login-wrapper {
    width: 300px;
    height: 350px;
  }
}
</style>
