/* eslint-disable */
import { GetMessageRemindCount } from '@/views/system/api';
import { GetClientMessagesRemindCount } from '@/views/client/api/message';
import { GetClientFollowUpsMessagesRemindCount } from '@/views/client/clientFollowUpsRecord/api/message';
import { GetClientInvitationsMessageRemindCount } from '@/views/client/clientInvitations/api/message';
import { GetContractsMessagesRemindCount } from '@/views/contracts/api/message';
import { getToken } from "@/utils/auth";
const state = {
	site_title: "此处是网站的标题",
	menus: [],
	isSideBarCollapse: false,
	codeToExport: false,
	version: '',
	remindMessageCount: 0,
	clientRemindMessageCount: 0,
	followUpsRemindMessageCount: 0,
	invitationsRemindMessageCount: 0,
	contractsRemindMessageCount: 0
}

const mutations = {
	SetMessageCount(state, payload) {
		state.remindMessageCount = payload
	},
	SetClientMessageCount(state, payload) {
		state.clientRemindMessageCount = payload
	},
	SetFollowUpsMessageCount(state, payload) {
		state.followUpsRemindMessageCount = payload
	},
	SetInvitationsMessageCount(state, payload) {
		state.invitationsRemindMessageCount = payload
	},
	SetContractsMessageCount(state, payload) {
		state.contractsRemindMessageCount = payload
	},
	SetSiteTitle(state, payload) {
		if (typeof payload === 'string') {
			state.site_title = payload
		}
	},
	SetMenus(state, payload) {
		if (payload.length > 0) {
			state.menus = payload
		}
	},
	setVersion(state, payload) {
		state.version = payload
	},
	SetCodeToExport(state, payload) {
		state.codeToExport = payload
	},
	ToggleSideBarCollapse(state) {
		state.isSideBarCollapse = !state.isSideBarCollapse
	},
	CloseSideBarCollapse(state) {
		state.isSideBarCollapse = true
	},
	OpenSideBarCollapse(state) {
		state.isSideBarCollapse = false
	}
}

const actions = {
	getGlobalMessageRemindCount({ dispatch, commit }) {
		if (getToken()) {
			GetMessageRemindCount().then(res => {
				commit('SetMessageCount', res.data)
			})
			dispatch("getClientMessageRemindCount")
			dispatch("getFollowUpsMessageRemindCount")
			dispatch("getInvitationsMessageRemindCount")
			dispatch("getContractsMessageRemindCount")
		}
	},
	getClientMessageRemindCount({ commit }) {
		if (getToken()) {
			GetClientMessagesRemindCount().then(res => {
				commit('SetClientMessageCount', res.data)
			})
		}
	},
	getFollowUpsMessageRemindCount({ commit }) {
		if (getToken()) {
			GetClientFollowUpsMessagesRemindCount().then(res => {
				commit('SetFollowUpsMessageCount', res.data)
			})
		}
	},
	getInvitationsMessageRemindCount({ commit }) {
		if (getToken()) {
			GetClientInvitationsMessageRemindCount().then(res => {
				commit('SetInvitationsMessageCount', res.data)
			})
		}
	},
	getContractsMessageRemindCount({ commit }) {
		if (getToken()) {
			GetContractsMessagesRemindCount().then(res => {
				commit('SetContractsMessageCount', res.data)
			})
		}
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
