import BasicLayout from "../../layouts/BasicLayout.vue";
let moduleName = "PaymentManager";
const RouteModules = [
  {
    path: "/payments",
    redirect: "/payments/list",
    name: "Companies",
    component: BasicLayout,
    showChildren: false,
    groupTitle: "收款管理",
    iconSvg: "paymentsManager",
    iconFont: "",
    onlyExtentdLayout: true,
    children: [
      {
        path: "/payments/list",
        name: "PaymentsList",
        component: () => import("@/views/payments/PaymentsList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "收款管理",
          keepAlive: true,
          iconSvg: "paymentsManager",
          iconFont: "",
          roles: ['PAYMENT_ADMIN', 'PAYMENT_GROUP_ADMIN', 'PAYMENT_USER', 'PAYMENT_OBSERVER']
        }
      }
    ]
  }
];

export default RouteModules;
