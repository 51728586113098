import BasicLayout from "../../layouts/BasicLayout.vue";
let moduleName = "MerchantsManager";
const RouteModules = [
  {
    path: "/merchants",
    redirect: "/merchants/list",
    name: "Merchants",
    component: BasicLayout,
    showChildren: false,
    groupTitle: "松露猪推荐",
    iconSvg: "recommended",
    iconFont: "",
    onlyExtentdLayout: true,
    children: [
      {
        path: "/merchants/list",
        name: "MerchantsList",
        component: () => import("@/views/merchants/MerchantsList.vue"),
        meta: {
          module: moduleName,
          isMenu: false,
          showChildren: false,
          title: "松露猪推荐",
          keepAlive: true,
          iconSvg: "recommended",
          iconFont: "",
          roles: []
        }
      }
    ]
  }
];

export default RouteModules;
