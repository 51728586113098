<template>
  <div class="user-login-wrapper">
    <div class="base-info">
      <div
        class="base-info-row"
        :title="`账号：${useInfo.username}`"
        @click="showAccountDetailDialog = true"
      >
        <svg-icon iconClass="account"></svg-icon> {{ useInfo.username }}
        <i class="el-icon-info" style="cursor: pointer"></i>
      </div>
      <div class="base-info-row" :title="`姓名：${useInfo.name}`">
        <i class="el-icon-user"></i> {{ useInfo.name }}
      </div>
      <div class="base-info-row" :title="`公司：${useInfo.companyName}`">
        <i class="el-icon-office-building"></i> {{ useInfo.companyName }}
      </div>
      <div class="base-info-row" :title="`部门：${useInfo.departmentName}`">
        <i class="el-icon-collection-tag"></i> {{ useInfo.departmentName }}
      </div>
      <div class="control">
        <el-button size="mini" @click="onEditPassword">修改密码</el-button>
        <el-button size="mini" @click="onEditPhone">变更手机号码</el-button>
        <!-- <el-button size="mini" @click="onActiveUser" v-if="isUserActive"
          >激活账户</el-button
        > -->
        <el-button type="primary" size="mini" @click="handleLogout"
          >退 出</el-button
        >
      </div>
    </div>
    <el-dialog
      title="修改密码"
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="showPasswordDialog"
      width="500px"
    >
      <CurrentUserPasswordEdit
        v-if="showPasswordDialog"
        @success="onEditPasswordSuccess"
      />
    </el-dialog>
    <el-dialog
      title="变更手机号码"
      append-to-body
      :close-on-click-modal="false"
      :visible.sync="showPhoneDialog"
      width="500px"
    >
      <CurrentUserPhoneEdit
        v-if="showPhoneDialog"
        @success="onEditPhoneSuccess"
        @close="onEditPhoneClose"
      />
    </el-dialog>

    <el-dialog
      title="账号详情"
      append-to-body
      :close-on-click-modal="true"
      :visible.sync="showAccountDetailDialog"
      width="80%"
    >
      <UserInfo />
    </el-dialog>
    <el-dialog
      title="激活账号"
      append-to-body
      :close-on-click-modal="false"
      :show-close="false"
      :visible="showActiveDialog"
      width="800px"
      @open="onActiveDialogOpen"
      @close="onActiveDialogClose"
    >
      <ActiveCurrentUser
        v-if="showActiveDialog"
        @success="onActiveSuccess"
        @cancel="onActiveCancel"
      />
    </el-dialog>
  </div>
</template>

<script>
import { logout } from "@/utils/auth";
import CurrentUserPasswordEdit from "@/components/CurrentUserPasswordEdit";
import CurrentUserPhoneEdit from "@/components/CurrentUserPhoneEdit";

import UserInfo from "@/components/UserInfo";
import ActiveCurrentUser from "@/components/ActiveCurrentUser";
import { checkPermission, GetUserInfo, getToken } from "@/utils/auth";
import { mapMutations } from "vuex";
export default {
  components: {
    CurrentUserPasswordEdit,
    CurrentUserPhoneEdit,
    ActiveCurrentUser,
    UserInfo,
  },
  computed: {
    useInfo() {
      return this.$store.getters.userInfo;
    },
    isUserActive() {
      return Object.keys(this.useInfo).length > 0 && !this.useInfo.activated;
    },
    showActiveDialog() {
      return this.$store.getters.showActiveDialog;
    },
  },
  data() {
    return {
      showAccountDetailDialog: false,
      showPasswordDialog: false,
      showPhoneDialog: false,
    };
  },
  methods: {
    ...mapMutations({
      ToggleActiveDialog: "auth/TOGGLE_ACTIVE_DIALOG",
    }),
    checkPermission,
    checkObjProp(obj = {}, prop = "") {
      Object.hasOwnProperty.call(obj, prop);
    },
    onEditPassword() {
      this.showPasswordDialog = true;
    },
    onEditPhone() {
      this.showPhoneDialog = true;
    },
    onEditPasswordSuccess() {
      this.$message.success("修改成功,请重新登录");
      this.showPasswordDialog = false;
      logout();
      this.$router.push("/login");
    },
    onActiveSuccess() {
      this.$message.success("激活成功");
      this.ToggleActiveDialog(false);
    },
    onActiveCancel() {
      this.ToggleActiveDialog(false);
    },
    onActiveDialogOpen() {
      this.ToggleActiveDialog(true);
    },
    onActiveDialogClose() {
      this.ToggleActiveDialog(false);
    },
    onEditPhoneSuccess() {
      this.$message.success("变更成功");
      this.showPhoneDialog = false;
      GetUserInfo(getToken());
    },
    onEditPhoneClose() {
      this.showPhoneDialog = false;
    },
    handleLogout() {
      this.$confirm("是否确认退出系统？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          logout();
          this.$router.replace("/login");
        })
        .catch(() => {});
    },
    onActiveUser() {
      this.$store.commit("auth/TOGGLE_ACTIVE_DIALOG", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.user-login-wrapper {
  width: 100%;
  max-width: 300px;
  min-height: 100px;
  ::v-deep {
    [class^="el-icon-"],
    [class*=" el-icon-"] {
      color: #333;
      font-size: 15px;
    }
  }
  .base-info-row {
    padding: 5px 0;
    font-size: 14px;
    color: #000;
  }
  .control {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
  }
}
</style>