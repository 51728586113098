import BasicLayout from "../../layouts/BasicLayout.vue";
let moduleName = "iconManager";
const RouteModules = [
    {
        path: "/icon/manager",
        redirect: "/icon/manager/svg/list",
        name: "iconManager",
        component: BasicLayout,
        showChildren: false,
        groupTitle: "图标管理",
        iconSvg: "icon",
        iconFont: "",
        children: [
            {
                path: "/icon/manager/svg/list",
                name: "SvgIconList",
                component: () => import("@/views/iconManager/SvgIconList.vue"),
                meta: {
                    module: moduleName,
                    isMenu: true,
                    showChildren: false,
                    title: "SVG图标",
                    keepAlive: true,
                    iconSvg: "icon",
                    iconFont: "",
                    roles: []
                }
            }
        ]
    }
];

export default RouteModules;
