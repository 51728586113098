const getters = {
  remindMessageCount: state => state.app.remindMessageCount,
  clientRemindMessageCount: state => state.app.clientRemindMessageCount,
  followUpsRemindMessageCount: state => state.app.followUpsRemindMessageCount,
  invitationsRemindMessageCount: state => state.app.invitationsRemindMessageCount,
  contractsRemindMessageCount: state => state.app.contractsRemindMessageCount,
  roles: state => state.auth.roles,
  userInfo: state => state.auth.userInfo,
  hasUserInfo: state => state.auth.hasUserInfo,
  showActiveDialog: state => state.auth.showActiveDialog,
  site_title: state => state.app.site_title,
  menus: state => state.app.menus,
  isSideBarCollapse: state => state.app.isSideBarCollapse,
  tagViews: state => state.tagsView.tagViews,
  userOptions: state => state.basicOption.userOptions,
  ownerOptions: state => state.basicOption.ownerOptions,
  clientOptions: state => state.basicOption.clientOptions,
  regionOptions: state => state.basicOption.regionOptions,
  cityOptions: state => state.basicOption.cityOptions,
  provinceOptions: state => state.basicOption.provinceOptions,
  countryOptions: state => state.basicOption.countryOptions,
  areaCascaderOptions: state => state.basicOption.areaCascader,
  chinaCascaderOptions: state => state.basicOption.chinaCascaderOptions,
  departmnetOptions: state => state.basicOption.departmnetOptions,
  departmnetTreeOptions: state => state.basicOption.departmnetTreeOptions

};
export default getters;
