import BasicLayout from "../../layouts/BasicLayout.vue";
let moduleName = "CompanyManager";
const RouteModules = [
  {
    path: "/clients",
    redirect: "/clients/list",
    name: "Client",
    component: BasicLayout,
    showChildren: true,
    groupTitle: "客户管理",
    iconSvg: "clientManager",
    iconFont: "",
    onlyExtentdLayout: false,
    children: [
      {
        path: "/clients/public/list",
        name: "ClientPublicList",
        component: () => import("@/views/client/clientPublic/ClientPublicList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "客户公海",
          keepAlive: true,
          iconSvg: "clients",
          iconFont: "",
          roles: ['CLIENT_ADMIN', 'CLIENT_GROUP_ADMIN', 'CLIENT_USER', 'CLIENT_OBSERVER']
        }
      },
      {
        path: "/clients/list",
        name: "CientsList",
        component: () => import("@/views/client/ClientList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "客户",
          keepAlive: true,
          iconSvg: "client",
          iconFont: "",
          roles: ['CLIENT_ADMIN', 'CLIENT_GROUP_ADMIN', 'CLIENT_USER', 'CLIENT_OBSERVER']
        }
      },
      {
        path: "/clients/contacts/list",
        name: "ClientContactsList",
        component: () => import("@/views/client/contacts/ContactsList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "联系人",
          keepAlive: true,
          iconSvg: "contact",
          iconFont: "",
          roles: ['CLIENT_ADMIN', 'CLIENT_GROUP_ADMIN', 'CLIENT_USER', 'CLIENT_OBSERVER']
        }
      },
      {
        path: "/clients/form/:id?/:type",
        name: "ClientsForm",
        component: () => import("@/views/client/ClientForm.vue"),
        meta: {
          module: moduleName,
          isMenu: false,
          showChildren: false,
          title: "客户编辑",
          keepAlive: true,
          iconSvg: "client",
          iconFont: "",
          roles: ['CLIENT_ADMIN', 'CLIENT_GROUP_ADMIN', 'CLIENT_USER']
        }
      },
      {
        path: "/clients/detail/:id?/:invitations?",
        name: "CientsDetail",
        component: () => import("@/views/client/ClientDetail.vue"),
        meta: {
          module: moduleName,
          isMenu: false,
          showChildren: false,
          title: "客户详情",
          keepAlive: true,
          iconSvg: "client",
          iconFont: "",
          roles: ['CLIENT_ADMIN', 'CLIENT_GROUP_ADMIN', 'CLIENT_USER', 'CLIENT_OBSERVER']
        }
      }
    ]
  }
];

export default RouteModules;
