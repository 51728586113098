<template>
  <el-form
    :model="detail"
    ref="companyForm"
    label-width="auto"
    :disabled="submitting"
  >
    <!-- <el-divider content-position="left">基本信息</el-divider> -->
    <el-row :gutter="10">
      <el-col :span="24">
        <el-form-item label="客户负责人超时日数：" prop="clientOwnerExpireDays">
          <el-input
            v-if="isEdit"
            v-model.trim="detail.clientOwnerExpireDays"
            clearable
          ></el-input>
          <span v-else>{{ detail.clientOwnerExpireDays }} 天</span>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="最后修改时间：" prop="lastModifiedAt">
          <span>{{ detail.lastModifiedAt | date_time }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="最后修改用户：" prop="lastModifiedByName">
          <span>{{ detail.lastModifiedByName }}</span>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="当前系统版本" prop="">
          <span v-show="!systemVersionEdit">{{ systemVersion }}</span> 
          <!-- <span v-show="!systemVersionEdit" class="text-nav" @click="systemVersionEdit = true">&nbsp;&nbsp;更新</span> -->
          <InlineForm v-show="systemVersionEdit" v-model="systemVersion" @confirm="onVersionConfrim" @cancel="onVersionCancel" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-form-item style="text-align: right">
      <el-button class="float-l" size="small" @click="onRefresh" plain
        >刷新数据</el-button
      >
      <el-button
        v-if="checkPermission(['SUPER_ADMIN'])"
        size="small"
        @click="onEdit"
        type="primary"
        plain
        :loading="canUpdateChecking"
      >
        <span v-if="canUpdateChecking">权限检验中...</span>
        <span v-else>{{ isEdit ? "取消编辑" : "编辑" }}</span>
      </el-button>
      <el-button
        v-if="isEdit"
        type="primary"
        size="small"
        @click="onSubmitForm('companyForm')"
        :loading="submitting"
        >{{ submitting ? "修改中..." : "提交修改" }}</el-button
      >

      <el-button size="small" @click="onCancel" plain>关闭</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import {
  GetGlobalSetting,
  PutGlobalSetting,
  GetGlobalSettingCheck,
  PutSystemVersion,
} from "@/views/system/api";
import { ShowApiError } from "@/request/error";
// import { isEmprty } from "@/utils/validate";
import { checkPermission } from "@/utils/auth";
import InlineForm from "@/components/InlineForm";
import { getSystemVersion } from "@/utils/version";
export default {
  components:{
    InlineForm
  },
  data() {
    return {
      isEdit: false,
      submitting: false,
      canUpdateChecking: false,
      canUpdate: false,
      systemVersion: "",
      systemVersionEdit: false,
      detail: {
        clientOwnerExpireDays: null,
        lastModifiedAt: "",
        lastModifiedById: null,
        lastModifiedByName: "",
      },
    };
  },
  created() {
    this.systemVersion = getSystemVersion() ? getSystemVersion() : "暂无"
    this.getGlobalSetting();
  },
  methods: {
    checkPermission,
    getGlobalSetting() {
      GetGlobalSetting()
        .then((res) => {
          this.detail = res.data;
        })
        .catch((err) => {
          ShowApiError("获取全局设置信息失败", err);
        });
    },
    onRefresh() {
      this.getGlobalSetting();
    },
    onSubmitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitting = true;
          PutGlobalSetting({
            clientOwnerExpireDays: this.detail.clientOwnerExpireDays,
          })
            .then(() => {
              this.isEdit = false;
              this.submitting = false;
              this.$message.success("修改成功");
              this.onRefresh();
            })
            .catch((err) => {
              this.submitting = false;
              ShowApiError("提交失败", err);
            });
        } else {
          this.$message.error("提交信息有误，请检查！");
          return false;
        }
      });
    },
    onEdit() {
      if (this.canUpdate) {
        this.isEdit = !this.isEdit;
        return false;
      }
      this.canUpdateChecking = true;
      GetGlobalSettingCheck()
        .then((res) => {
          this.canUpdate = res.data;
          this.canUpdateChecking = false;
          if (res.data) {
            this.isEdit = !this.isEdit;
          } else {
            this.$message.warning("没有权限执行此操作!");
          }
        })
        .catch(() => {
          this.canUpdate = false;
          this.canUpdateChecking = false;
          this.$message.warning("权限校验异常，请稍后再试");
        });
    },
    onCancel() {
      this.isEdit = false;
      this.$emit("cancel");
    },
    onVersionConfrim(){
      PutSystemVersion({version: this.systemVersion}).then(() => {
        this.$message.success('版本号更新成功')
        this.systemVersionEdit = false
      }).catch(() => {
        this.$message.error('版本号更新失败')
      })
    },
onVersionCancel(){
  this.systemVersionEdit = false
},
  },
};
</script>

<style lang="scss" scoped>
</style>