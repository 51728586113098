import BasicLayout from "../../layouts/BasicLayout.vue";
let moduleName = "ContractsManager";
const RouteModules = [
  {
    path: "/contracts",
    redirect: "/contracts/list",
    name: "Contracts",
    component: BasicLayout,
    showChildren: false,
    groupTitle: "合同管理",
    iconSvg: "contracts",
    iconFont: "",
    onlyExtentdLayout: true,
    children: [
      {
        path: "/contracts/list",
        name: "ContractsList",
        component: () => import("@/views/contracts/ContractsList.vue"),
        meta: {
          module: moduleName,
          isMenu: true,
          showChildren: false,
          title: "合同管理",
          keepAlive: true,
          iconSvg: "contracts",
          iconFont: "",
          roles: ['CONTRACT_ADMIN', 'CONTRACT_GROUP_ADMIN', 'CONTRACT_USER', 'CONTRACT_OBSERVER']
        }
      },
      {
        path: "/contracts/edit/:id?/form/:clientId?",
        name: "ContractsForm",
        component: () => import("@/views/contracts/ContractsForm.vue"),
        meta: {
          module: moduleName,
          isMenu: false,
          showChildren: false,
          title: "合同编辑",
          keepAlive: true,
          iconSvg: "contracts",
          iconFont: "",
          roles: ['CONTRACT_ADMIN', 'CONTRACT_GROUP_ADMIN', 'CONTRACT_USER']
        }
      },
      {
        path: "/contracts/detail/:id?",
        name: "ContractsDetail",
        component: () => import("@/views/contracts/ContractsDetail.vue"),
        meta: {
          module: moduleName,
          isMenu: false,
          showChildren: false,
          title: "合同详情",
          keepAlive: true,
          iconSvg: "contracts",
          iconFont: "",
          roles: ['CONTRACT_ADMIN', 'CONTRACT_GROUP_ADMIN', 'CONTRACT_USER', 'CONTRACT_OBSERVER']
        }
      }
    ]
  }
];

export default RouteModules;
